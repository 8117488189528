@import '../../style/mixins/index';

@sidebar-prefix-cls: ~'@{c7n-prefix}-modal-sidebar';
@modal-prefix-cls: ~'@{c7n-prefix}-modal';

.@{sidebar-prefix-cls} {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  outline: 0;
  transform-origin: 100% 0% !important;
  opacity: 0;

  &&-open {
    opacity: 1;
  }
  .@{modal-prefix-cls}-content {
    position: absolute;
    left: 3.52rem;
    width: calc(~'100% - 3.52rem');
    height: 100%;
    padding: 0;
    overflow: auto;
  }

  &-fixed-width {
    right: 0;

    .@{modal-prefix-cls}-content {
      left: auto;
      width: 100%;
    }
  }

  .@{modal-prefix-cls}-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: @sidebar-header-height;
    min-height: @sidebar-header-height;
    padding: 0 0.08rem;
    white-space: nowrap;
    border-bottom: @border-width-base @border-style-base @border-color-base;

    .@{modal-prefix-cls}-title {
      margin: 0 0.56rem 0 0.16rem;
      font-size: @sidebar-font-size-lg;
      line-height: 0.24rem;
      letter-spacing: 0.005em;
    }
  }

  .@{modal-prefix-cls}-body {
    position: relative;
    max-height: calc(~'100% - 1.21rem') !important;
    padding: 0.24rem;
    overflow: auto;
    font-size: 100%;
  }

  .@{modal-prefix-cls}-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 0.56rem;
    padding: 0 0.24rem;
    white-space: nowrap;
    border-top: @border-width-base @border-style-base @border-color-base;
    button + button {
      margin-bottom: 0;
      margin-left: 0.12rem;
    }
    button.cancel {
      color: @primary-color;
    }
    .@{modal-prefix-cls}-btn-cancel {
      color: @primary-color;
    }
  }
}
