@import '../../style/themes/default';
@import '../../style/mixins/index';
@import '../../input/style/mixin';

@pagination-prefix-cls: ~'@{c7n-prefix}-pagination';

.@{pagination-prefix-cls} {
  .reset-component;

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
  }

  &-total-text {
    display: inline-block;
    height: @pagination-item-size;
    margin-right: 0.24rem;
    color: rgba(0, 0, 0, 0.66);
    line-height: @pagination-item-size;
    vertical-align: middle;
  }

  &-item {
    display: inline-block;
    min-width: @pagination-item-size;
    height: @pagination-item-size;
    margin-right: 0.08rem;
    font-family: @pagination-font-family;
    line-height: @pagination-item-size - 0.02rem;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: @component-background;
    border-radius: @border-radius-base;
    outline: 0;
    cursor: pointer;
    user-select: none;

    a {
      margin: 0 0.06rem;
      color: @text-color;
      text-decoration: none;
      transition: none;
    }

    &:focus,
    &:hover {
      border-color: @primary-color;
      transition: all 0.3s;
      a {
        color: @primary-color;
      }
    }

    &-active {
      font-weight: @pagination-font-weight-active;
      border-color: @primary-color;

      a {
        color: @primary-color;
      }

      &:focus,
      &:hover {
        border-color: @primary-5;
      }

      &:focus a,
      &:hover a {
        color: @primary-5;
      }
    }
  }

  &-first,
  &-last,
  &-prev,
  &-jump-prev,
  &-jump-next {
    margin-right: 0.08rem;
  }

  &-first,
  &-last,
  &-prev,
  &-next,
  &-jump-prev,
  &-jump-next {
    display: inline-block;
    min-width: @pagination-item-size;
    height: @pagination-item-size;
    color: @text-color;
    font-family: @pagination-font-family;
    line-height: @pagination-item-size;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: @border-radius-base;
    cursor: pointer;
    transition: all 0.3s;
  }

  &-first,
  &-last,
  &-prev,
  &-next {
    outline: 0;

    a {
      color: @text-color;
      user-select: none;
    }

    &:hover a {
      border-color: @primary-5;
    }

    .@{pagination-prefix-cls}-item-link {
      .iconfont-mixin(0.24rem);
      display: block;
      background-color: @component-background;
      outline: none;
      transition: all 0.3s;

      &::before {
        display: block;
        height: @pagination-item-size - 0.02rem;
        font-weight: 500;
        line-height: @pagination-item-size;
        text-align: center;
      }
    }

    &:focus .@{pagination-prefix-cls}-item-link,
    &:hover .@{pagination-prefix-cls}-item-link {
      color: @primary-color;
      border-color: @primary-color;
    }
  }

  &-first {
    .@{pagination-prefix-cls}-item-link::before {
      display: block;
      content: '\e5dc';
    }
  }

  &-last {
    .@{pagination-prefix-cls}-item-link::before {
      display: block;
      content: '\e5dd';
    }
  }

  &-prev {
    .@{pagination-prefix-cls}-item-link::before {
      display: block;
      content: '\e314';
    }
  }

  &-next {
    .@{pagination-prefix-cls}-item-link::before {
      display: block;
      content: '\e315';
    }
  }

  &-disabled {
    &,
    &:hover,
    &:focus {
      cursor: not-allowed;
      a,
      .@{pagination-prefix-cls}-item-link {
        color: @disabled-color;
        border-color: @border-color-base;
        cursor: not-allowed;
      }
    }
    .@{c7n-prefix}-btn[disabled] {
      background-color: transparent;
    }
  }

  &-slash {
    margin: 0 0.1rem 0 0.05rem;
  }

  &-options {
    display: inline-block;
    height: @pagination-item-size;
    margin-left: 0.16rem;
    line-height: @pagination-item-size;
    vertical-align: middle;

    &-size-changer.@{c7n-prefix}-select {
      float: right;
      width: auto;
      height: @pagination-item-size;
      line-height: @pagination-item-size - 0.03rem;

      .@{c7n-prefix}-select-selection {
        min-width: 0.64rem;
        height: auto;
        padding-top: 0;
        text-align: right;
        &__rendered {
          display: block;
          height: 0.32rem;
          line-height: 0.3rem;
        }

        &-selected-value {
          display: inline-block;
          vertical-align: middle;
        }

        .@{c7n-prefix}-select-arrow {
          display: inline-block;
          vertical-align: inherit;
          > i.@{iconfont-css-prefix} {
            vertical-align: text-bottom;
          }
        }
      }
      .@{c7n-prefix}-select-underline {
        display: none;
      }
    }

    &-quick-jumper {
      display: inline-block;
      height: @input-height-base;
      line-height: @input-height-base;
      vertical-align: middle;

      input {
        .input;
        width: 0.5rem;
        height: @input-height-base;
        margin: 0 0.08rem;
      }
    }

    &-label {
      display: inline-block;
      margin-right: 0.04rem;
      color: rgba(0, 0, 0, 0.66);
      letter-spacing: 0.01em;
    }
  }

  &-simple &-prev,
  &-simple &-next {
    height: @pagination-item-size-sm;
    line-height: @pagination-item-size-sm;
    vertical-align: top;
    .@{pagination-prefix-cls}-item-link {
      height: @pagination-item-size-sm;
      border: 0;
      &::before {
        height: @pagination-item-size-sm;
        line-height: @pagination-item-size-sm;
      }
    }
  }

  &-simple &-simple-pager {
    display: inline-block;
    height: @pagination-item-size-sm;
    margin-right: 0.08rem;

    input {
      box-sizing: border-box;
      height: 100%;
      margin-right: 0.08rem;
      padding: 0 0.06rem;
      text-align: center;
      background-color: @component-background;
      border: @border-width-base @border-style-base @border-color-base;
      border-radius: @border-radius-base;
      outline: none;
      transition: border-color 0.3s;

      &:hover {
        border-color: @primary-color;
      }
    }
  }

  &-tiny {
    .@{pagination-prefix-cls}-options {
      margin-right: 0.16rem;
    }
  }

  &-small &-total-text,
  &-small &-simple-pager {
    height: @pagination-item-size-sm;
    line-height: @pagination-item-size-sm;
  }

  &-small &-item {
    min-width: @pagination-item-size-sm;
    height: @pagination-item-size-sm;
    margin: 0;
    line-height: @pagination-item-size-sm - 0.02rem;
  }

  &-small &-item:not(&-item-active) {
    background: transparent;
    border-color: transparent;
  }

  &-small &-first,
  &-small &-last,
  &-small &-prev,
  &-small &-next {
    min-width: @pagination-item-size-sm;
    height: @pagination-item-size-sm;
    margin: 0;
    line-height: @pagination-item-size-sm;
  }

  &-small &-first &-item-link,
  &-small &-last &-item-link,
  &-small &-prev &-item-link,
  &-small &-next &-item-link {
    background: transparent;
    border-color: transparent;
    &::before {
      height: @pagination-item-size-sm;
      line-height: @pagination-item-size-sm;
    }
  }

  &-small &-jump-prev,
  &-small &-jump-next {
    height: @pagination-item-size-sm;
    margin-right: 0;
    line-height: @pagination-item-size-sm;
  }

  &-small &-options {
    height: @pagination-item-size-sm;
    &-quick-jumper {
      height: @pagination-item-size-sm;
      line-height: @pagination-item-size-sm;

      input {
        .input-sm;
        width: 0.44rem;
        height: @pagination-item-size-sm;
      }
    }
  }
  
  &-large {
    font-size: @font-size-base;
    i.icon {
      font-size: @font-size-base;
    }
  }

  &-large &-total-text,
  &-large &-simple-pager {
    height: @pagination-item-size-lg;
    line-height: @pagination-item-size-lg;
  }

  &-large &-item {
    min-width: @pagination-item-size-lg;
    height: @pagination-item-size-lg;
    margin: 0;
    line-height: @pagination-item-size-lg - 0.02rem;
  }

  &-large &-item:not(&-item-active) {
    background: transparent;
    border-color: transparent;
  }

  &-large &-first,
  &-large &-last,
  &-large &-prev,
  &-large &-next {
    min-width: @pagination-item-size-lg;
    height: @pagination-item-size-lg;
    margin: 0;
    line-height: @pagination-item-size-lg;
  }

  &-large &-first &-item-link,
  &-large &-last &-item-link,
  &-large &-prev &-item-link,
  &-large &-next &-item-link {
    background: transparent;
    border-color: transparent;
    &::before {
      height: @pagination-item-size-lg;
      line-height: @pagination-item-size-lg;
    }
  }

  &-large &-jump-prev,
  &-large &-jump-next {
    height: @pagination-item-size-lg;
    margin-right: 0;
    line-height: @pagination-item-size-lg;
  }

  &-large &-options {
    height: @pagination-item-size-lg;
    &-quick-jumper {
      height: @pagination-item-size-lg;
      line-height: @pagination-item-size-lg;

      input {
        .input-sm;
        width: 0.44rem;
        height: @pagination-item-size-lg;
      }
    }
  }
}

@media only screen and (max-width: @screen-lg) {
  .@{pagination-prefix-cls}-item {
    &-after-jump-prev,
    &-before-jump-next {
      display: none;
    }
  }
}

@media only screen and (max-width: @screen-sm) {
  .@{pagination-prefix-cls}-options {
    display: none;
  }
}
