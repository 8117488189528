@import '../../style/themes/default';
@import '../../style/mixins/index';

@select-prefix-cls: ~'@{c7n-prefix}-select';
@icon-select-prefix-cls: ~'@{c7n-prefix}-icon-select';

.@{icon-select-prefix-cls} {
  i.@{iconfont-css-prefix} {
    vertical-align: text-bottom;
  }
  &-dropdown {
    min-width: 5.12rem;
    .@{select-prefix-cls}-dropdown-menu {
      max-height: 3rem;
      i {
        display: inline-block;
        width: 100%;
        margin-top: 0.05rem;
        font-size: 0.3rem;
        text-align: center;
      }
      .text {
        display: block;
        margin-bottom: 0.05rem;
        overflow: hidden;
        line-height: 0.18rem;
        text-align: center;
        text-overflow: ellipsis;
      }
      li {
        padding: 0 0.05rem !important;
      }
    }
    .@{select-prefix-cls}-dropdown-filter + div > .@{select-prefix-cls}-dropdown-menu li {
      display: inline-block;
      width: 0.85rem;
      margin: 0 0.07rem;
      padding: 0 0.12rem;
      overflow: hidden;
      line-height: 0.28rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      i.@{iconfont-css-prefix} {
        vertical-align: text-bottom;
      }
    }
    .@{select-prefix-cls}-dropdown-footer {
      text-align: right;
    }
  }
}
