
.dropdown-menu-item () {
  &-item-group {
    padding-top: 0.04rem;
    padding-bottom: 0.04rem;
    &:not(:last-child) {
      border-bottom: @border-width-base @border-style-base @border-color-base;
    }
  }
  &-item-group-list {
    margin: 0;
    padding: 0;
  }

  &-item-group-list > &-item {
    padding-left: 0.2rem;
  }
  &-item-group-no-title > &-item {
    padding-left: @control-padding-horizontal;
  }

  &-item-group-title {
    height: 0.32rem;
    padding: 0 @control-padding-horizontal;
    color: @text-color-secondary;
    font-size: @font-size-sm;
    line-height: 0.32rem;
  }

  &-item {
    position: relative;
    display: block;
    height: auto;
    padding: 0.04rem @control-padding-horizontal;
    overflow: hidden;
    color: @text-color;
    font-weight: normal;
    line-height: 0.2rem;
    letter-spacing: 0.01em;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background-color: @item-active-bg;
    }

    &:first-child {
      border-radius: @border-radius-base @border-radius-base 0 0;
    }

    &:last-child {
      border-radius: 0 0 @border-radius-base @border-radius-base;
    }

    &-disabled {
      color: @input-disabled-color;
      cursor: not-allowed;

      &:hover {
        color: @input-disabled-color;
        background-color: @component-background;
        cursor: not-allowed;
      }
    }

    &-selected {
      background-color: @item-focus-bg;
    }

    &-active {
      background-color: @item-active-bg;
    }

    &-divider {
      height: 0.01rem;
      margin: 0.01rem 0;
      overflow: hidden;
      line-height: 0;
      background-color: @border-color-split;
    }
  }
}

.dropdown-menu () {
  max-height: 2.5rem;
  margin-bottom: 0;
  padding: 0.08rem 0;
  overflow: auto;
  list-style: none;
  outline: none;
  .dropdown-menu-item;
}
