@import '../../style/themes/default';
@import '../../style/mixins/index';
@import '../../checkbox/style/mixin';

@transfer-prefix-cls: ~'@{c7n-prefix}-transfer';

.@{transfer-prefix-cls} {
  .reset-component;
  position: relative;

  &-list {
    position: relative;
    display: inline-block;
    width: 1.8rem;
    height: 2rem;
    padding-top: 0.34rem;
    vertical-align: middle;
    border: @border-width-base @border-style-base @border-color-base;
    border-radius: @border-radius-base;

    &-with-footer {
      padding-bottom: 0.34rem;
    }

    &-search {
      padding: 0 @control-padding-horizontal-sm;
      &-action {
        position: absolute;
        top: 0.04rem;
        right: 0.04rem;
        bottom: 0.04rem;
        width: 0.28rem;
        color: @disabled-color;
        line-height: @input-height-base;
        text-align: center;
        .@{iconfont-css-prefix} {
          color: @disabled-color;
          transition: all 0.3s;
          &:hover {
            color: @text-color-secondary;
          }
        }
        span& {
          pointer-events: none;
        }
      }
    }

    &-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.06rem @control-padding-horizontal;
      overflow: hidden;
      color: @text-color;
      background: @component-background;
      border-bottom: @border-width-base @border-style-base @border-color-split;
      border-radius: @border-radius-base @border-radius-base 0 0;

      &-title {
        position: absolute;
        right: 0.12rem;
      }
    }

    &-body {
      position: relative;
      height: 100%;
      font-size: @font-size-base;

      &-search-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0.04rem;
      }
    }

    &-body-with-search {
      padding-top: @input-height-base + 0.08rem;
    }

    &-content {
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: auto;
      list-style: none;
      > .LazyLoad {
        animation: transferHighlightIn 1s;
      }

      &-item {
        min-height: 0.32rem;
        padding: 0.06rem @control-padding-horizontal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: all 0.3s;
        > span {
          padding-right: 0;
        }
      }

      &-item:not(&-item-disabled):hover {
        background-color: @item-hover-bg;
        cursor: pointer;
      }

      &-item-disabled {
        color: @btn-disable-color;
        cursor: not-allowed;
      }
    }

    &-body-not-found {
      position: absolute;
      top: 50%;
      display: none;
      width: 100%;
      margin-top: -0.1rem;
      padding-top: 0;
      color: @disabled-color;
      text-align: center;
    }

    &-content:empty + &-body-not-found {
      display: block;
    }

    &-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: @border-width-base @border-style-base @border-color-split;
      border-radius: 0 0 @border-radius-base @border-radius-base;
    }
  }

  &-operation {
    display: inline-block;
    margin: 0 0.08rem;
    overflow: hidden;
    vertical-align: middle;

    .@{c7n-prefix}-btn {
      display: block;

      &:first-child {
        margin-bottom: 0.04rem;
      }

      .@{iconfont-css-prefix} {
        font-size: 0.12rem;
      }
    }
  }
}

@keyframes transferHighlightIn {
  0% {
    background: @primary-2;
  }
  100% {
    background: transparent;
  }
}
