@import '../../style/themes/default';
@import '../../style/mixins/index';

@input-number-prefix-cls: ~'@{c7n-prefix}-input-number';

.@{input-number-prefix-cls} {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;

  &-handler {
    position: relative;
    display: block;
    width: 100%;
    height: 0.1rem;
    overflow: hidden;
    font-weight: bold;
    line-height: 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s linear;
    &:hover &-up-inner,
    &:hover &-down-inner {
      color: @primary-5;
    }
  }

  &-handler-up-inner,
  &-handler-down-inner {
    cursor: pointer;
    transition: all 0.1s linear;
    user-select: none;
  }

  &-handler-up-inner {
    position: absolute;
    right: 0.02rem;
    bottom: -0.05rem;
  }
  &-handler-down-inner {
    position: absolute;
    top: -0.05rem;
    right: 0.02rem;
  }

  &-disabled {
    .@{input-number-prefix-cls}-handler-wrap {
      display: none;
    }
  }

  &-handler-wrap {
    position: absolute;
    top: 0;
    right: 0.01rem;
    bottom: 0;
    z-index: 2;
    width: 0.22rem;
    height: 0.2rem;
    margin: auto;
    color: #000;
    background: @component-background;
    border-radius: 0 @border-radius-base @border-radius-base 0;
    opacity: 0;
    transition: opacity 0.24s linear 0.1s;
  }

  &:hover &-handler-wrap {
    opacity: 1;
  }

  &-handler-up-disabled,
  &-handler-down-disabled {
    cursor: not-allowed;
  }

  &-handler-up-disabled:hover &-handler-up-inner,
  &-handler-down-disabled:hover &-handler-down-inner {
    color: @disabled-color;
  }
}
