@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import 'mixin';

@modal-prefix-cls: ~'@{c7n-pro-prefix}-modal';

.@{modal-prefix-cls} {
  position: fixed;
  top: 1rem;
  width: 5.2rem;
  margin: 0 auto;
  // min-height: 2.4rem;

  &-center {
    right: 0;
    left: 0;
  }

  &-content {
    position: relative;
    height: 100%;
    min-height: inherit;
    background-color: @component-background;
    background-clip: padding-box;
    border: 0;
    border-radius: @border-radius-base;
    box-shadow: @modal-content-shadow;
  }

  &-title {
    margin: 0;
    color: @modal-title-color;
    font-weight: @modal-title-font-weight;
    font-size: @modal-title-font-size-base;
    line-height: @modal-title-line-height;
  }

  &-header {
    position: relative;
    padding: 0.15rem 0.24rem;
    color: @text-color;
    background: @component-background;
    border-radius: @border-radius-base @border-radius-base 0 0;
  }

  &-body {
    max-height: ~'calc(100vh - 1.2rem)';
    padding: 0.24rem;
    overflow: auto;
    font-size: @font-size-base;
    line-height: @line-height-base;
  }

  &-footer {
    padding: 0.12rem 0.24rem;
    text-align: right;
    border-radius: 0 0 @border-radius-base @border-radius-base;

    button + button {
      margin-bottom: 0;
      margin-left: 0.08rem;
    }

    &-drawer {
      flex-shrink: 0;
      text-align: left;
    }

    &-without-border {
      padding-top: 0;
      border-top: none;
    }
  }

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: @zindex-modal-container;
  }

  .active;
  .border;

  &-title-none {
    border-bottom: none !important;
  }

  &-header-buttons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &-header-button {
    width: 0.55rem;
    height: 100%;
    padding: 0;
    color: @text-color-secondary;
    font-weight: 700;
    font-size: @font-size-lg;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;

    &:focus,
    &:hover {
      color: #444;
    }
  }

  &-movable {
    cursor: move;
    user-select: none;
  }

  &-fullscreen {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;

    .@{modal-prefix-cls}-content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      border-radius: @border-radius-base;
    }

    .@{modal-prefix-cls}-body {
      flex-grow: 1;
      max-height: none;
    }

    .@{modal-prefix-cls}-footer {
      flex-shrink: 0;
    }
  }

  .drawer(@modal-prefix-cls);

  &-auto-center {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &-auto-center &-content {
    width: 100%;
    min-width: 5.2rem;
    height: min-content;
  }
}

.@{c7n-pro-prefix}-confirm {
  &-icon {
    padding-right: 0.16rem;
    vertical-align: top;

    .@{iconfont-css-prefix} {
      font-size: 0.24rem;
      line-height: 1.5;
    }
  }

  &-info {
    color: @info-color;
  }

  &-confirm,
  &-warning {
    color: @warning-color;
  }

  &-success {
    color: @success-color;
  }

  &-error {
    color: @error-color;
  }

  &-title {
    overflow: auto;
    color: @modal-confirm-title-color;
    font-weight: @modal-confirm-title-font-weight;
    font-size: @modal-confirm-title-font-size-base;
    line-height: @modal-confirm-title-line-height;
  }

  &-content {
    color: @text-color;
    font-size: @font-size-base;
  }

  &-title + &-content {
    margin-top: 0.08rem;
  }
}

.@{c7n-pro-prefix}-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: @modal-mask-bg;
}
