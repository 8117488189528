@import '../../style/mixins/index';

.antCheckboxFn(@checkbox-prefix-cls: ~'@{c7n-prefix}-checkbox') {
  @checkbox-inner-prefix-cls: ~'@{checkbox-prefix-cls}-inner';
  // 一般状态
  .@{checkbox-prefix-cls} {
    .reset-component;
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: pointer;

    &-checked::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: @border-radius-sm;
      content: '';
    }

    &:hover::after,
    .@{checkbox-prefix-cls}-wrapper:hover &::after {
      visibility: visible;
    }

    &-inner {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: @checkbox-size;
      height: @checkbox-size;
      background-color: #fff;
      border: @checkbox-border-width @border-style-base @checkbox-border-color;
      border-radius: @border-radius-sm;
      transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1),
        background-color 90ms cubic-bezier(0, 0, 0.2, 0.1);

      &::after {
        @check-width: (@checkbox-size / 14) * 5;
        @check-height: (@checkbox-size / 14) * 10;
        position: absolute;
        top: (@checkbox-size - @check-height) / 2 - @check-height * 0.3;
        left: (@checkbox-size - @check-width) / 2 - @check-width * 0.3;
        display: table;
        width: @check-width;
        height: @check-height;
        border: 0.02rem solid #fff;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(0);
        transition: all 0.1s @ease-in-back;
        content: ' ';
      }
    }

    &-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }

  // 选中状态
  .@{checkbox-prefix-cls}-checked .@{checkbox-inner-prefix-cls}::after {
    position: absolute;
    display: table;
    border: 0.02rem solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1);
    transition: all 0.2s @ease-out-back 0.1s;
    content: ' ';
  }

  .@{checkbox-prefix-cls}-checked,
  .@{checkbox-prefix-cls}-indeterminate {
    .@{checkbox-inner-prefix-cls} {
      background-color: @checkbox-color;
      border-color: @checkbox-color;
    }
  }

  .@{checkbox-prefix-cls}-disabled {
    cursor: not-allowed;

    &.@{checkbox-prefix-cls}-checked {
      .@{checkbox-inner-prefix-cls}::after {
        border-color: @disabled-color;
        animation-name: none;
      }
    }

    .@{checkbox-prefix-cls}-input {
      cursor: not-allowed;
    }

    .@{checkbox-inner-prefix-cls} {
      background-color: @input-disabled-bg;
      border-color: @border-color-base !important;
      &::after {
        border-color: @input-disabled-bg;
        animation-name: none;
      }
    }

    & + span {
      color: @input-disabled-color;
      cursor: not-allowed;
    }
  }

  .@{checkbox-prefix-cls}-wrapper {
    .reset-component;
    display: inline-block;
    line-height: unset;
    cursor: pointer;
    & + & {
      margin-left: 0.08rem;
    }
  }

  .@{checkbox-prefix-cls}-wrapper + span,
  .@{checkbox-prefix-cls} + span {
    padding-right: 0.08rem;
    padding-left: 0.08rem;
  }

  .@{checkbox-prefix-cls}-group {
    display: inline-block;
    &-item {
      display: inline-block;
      margin-right: 0.08rem;
      &:last-child {
        margin-right: 0;
      }
    }
    &-item + &-item {
      margin-left: 0;
    }
    &-wrapper {
      .reset-component;
      display: inline-block;
    }
    &-wrapper&-has-label {
      position: relative;
    }
    &-wrapper&-has-label & {
      padding-top: 0.17rem;
    }
    &-wrapper &-label.label-disabled {
      color: rgba(0, 0, 0, 0.54);
    }
    &-wrapper &-label {
      position: absolute;
      left: 0;
      width: 100%;
      color: rgba(0, 0, 0, 0.6);
      transform: scale(0.75) translateY(0) translateZ(0.00001rem);
      transform-origin: 0 0;
    }
  }

  // 半选状态
  .@{checkbox-prefix-cls}-indeterminate .@{checkbox-inner-prefix-cls}::after {
    @indeterminate-width: (@checkbox-size / 14) * 8;
    @indeterminate-height: @checkbox-size / 14;
    position: absolute;
    top: (@checkbox-size - 0.04rem - @indeterminate-height) / 2;
    left: (@checkbox-size - 0.03rem - @indeterminate-width) / 2;
    width: @indeterminate-width;
    height: @indeterminate-height;
    transform: scale(1);
    content: ' ';
  }

  .@{checkbox-prefix-cls}-indeterminate.@{checkbox-prefix-cls}-disabled
    .@{checkbox-inner-prefix-cls}::after {
    border-color: @disabled-color;
  }
}

@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
