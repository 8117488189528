@import '../../../components/style/themes/default';
@import '../../check-box/style/mixin';

@table-customization-prefix-cls: ~'@{c7n-pro-prefix}-table-customization';

.@{table-customization-prefix-cls} {
  &-header-button {
    float: right;
  }

  &-tree {
    min-height: .01rem;
    margin-bottom: .06rem;

    &-treenode {
      position: relative;
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding: .03rem 0 .03rem;
      outline: none;

      &-hover-button {
        visibility: hidden;
        transition: none;

        .@{iconfont-css-prefix} {
          transition: none;
        }
      }

      &-hover &-hover-button {
        visibility: visible;
      }

      &-hover {
        &::before {
          position: absolute;
          top: 0;
          right: -100%;
          bottom: 0;
          left: -100%;
          display: block;
          background-color: @item-hover-bg;
          content: "";
        }
      }

      &-content {
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        width: 100%;
        height: .32rem;
        min-height: .24rem;
        margin: 0;
        padding: .04rem 0;
        color: inherit;
        line-height: .24rem;
        background: transparent;
        border-radius: .02rem 0 0 .02rem;
      }

      &-title {
        display: flex;
        flex: 1;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        user-select: none;

        &-text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-switcher {
        flex: none;
        width: .22rem;
        height: .32rem;
        margin: 0;
        padding: .05rem 0;
        line-height: 1;
        text-align: center;
        vertical-align: top;
        cursor: pointer;

        &-icon {
          font-size: .18rem;
          line-height: .22rem;

          &-close {
            transform: rotate(-90deg);
          }
        }

        &-noop {
          cursor: default;
        }
      }

      &-drag-icon {
        color: @text-color-secondary;
      }

      &-dragging {
        background-color: @component-background;
        box-shadow: @shadow-4;
      }
    }

    &-group-header {
      display: inline-block;
      margin-bottom: .1rem;
      color: @float-label-color;
      font-size: @font-size-sm;
    }
  }

  &-panel {
    &-title {
      font-weight: 600;
      font-size: .14rem;
      line-height: .22rem;
      vertical-align: middle;
    }
  }

  &-form {
    padding-top: @table-customization-form-padding-vertical;
  }

  &-option {
    display: flex !important;
    align-items: center;
    min-height: .32rem;
    margin: 0 !important;
    padding: .03rem 0;

    &:first-child {
      margin-top: .05rem !important;
    }

    &-label {
      flex: 1;
    }

    &-input {
      width: 1rem;
      margin-left: .1rem;
    }

    .@{c7n-pro-prefix}-radio-label {
      display: inline-flex;
      flex: 1;
      align-items: center;
    }
  }

  & &-select-view-option {
    margin-top: .1rem !important;
    margin-right: .25rem;
    margin-bottom: .1rem !important;
    padding: 0;

    &-inner {
      width: .7rem;
      height: .41rem;
      background-repeat: no-repeat;
    }

    .@{c7n-pro-prefix}-radio {
      &-inner {
        border-width: .02rem;
        pointer-events: none;
      }

      &:checked + .@{c7n-pro-prefix}-radio-inner {
        &::before {
          top: 0;
          right: 0;
          left: auto;
          display: block;
          width: .14rem;
          height: .14rem;
          border-radius: 0;
          content: '';
        }

        .uncheck-right(.14rem);
        .check;

        &::after {
          left: auto;
        }
      }
    }
  }

  &-size-default,
  &-not-aggregation {
    background-image: linear-gradient(90deg, @table-customization-select-view-option-bg .04rem, transparent .04rem, transparent .08rem, @table-customization-select-view-option-bg .08rem, @table-customization-select-view-option-bg 100%),
    linear-gradient(90deg, @table-customization-select-view-option-bg 0, @table-customization-select-view-option-bg 100%),
    linear-gradient(90deg, @table-customization-select-view-option-bg .04rem, transparent .04rem, transparent .08rem, @table-customization-select-view-option-bg .08rem, @table-customization-select-view-option-bg 100%);
    background-position: .06rem .08rem, .02rem .2rem, .06rem .28rem;
    background-size: .56rem .04rem, .67rem .01rem, .56rem .04rem;
  }

  &-aggregation {
    background-image: linear-gradient(90deg, @table-customization-select-view-option-bg .03rem, transparent .03rem, transparent .08rem, @table-customization-select-view-option-bg .08rem, @table-customization-select-view-option-bg .32rem, transparent .32rem, transparent .36rem, @table-customization-select-view-option-bg .36rem),
    linear-gradient(90deg, @table-customization-select-view-option-bg .2rem, transparent .2rem, transparent .28rem, @table-customization-select-view-option-bg .28rem),
    linear-gradient(90deg, @table-customization-select-view-option-bg 0, @table-customization-select-view-option-bg 100%),
    linear-gradient(90deg, @table-customization-select-view-option-bg .03rem, transparent .03rem, transparent .08rem, @table-customization-select-view-option-bg .08rem, @table-customization-select-view-option-bg .32rem, transparent .32rem, transparent .36rem, @table-customization-select-view-option-bg .36rem),
    linear-gradient(90deg, @table-customization-select-view-option-bg .2rem, transparent .2rem, transparent .28rem, @table-customization-select-view-option-bg .28rem);
    background-position: .06rem .06rem, .14rem .12rem, .02rem .2rem, .06rem .26rem, .14rem .32rem;
    background-size: .56rem .03rem, .44rem .03rem, .67rem .01rem, .56rem .03rem, .44rem .03rem;
  }

  &-size-small,
  &-no-parity-row {
    background-image: linear-gradient(90deg, @table-customization-select-view-option-bg .03rem, transparent .03rem, transparent .08rem, @table-customization-select-view-option-bg .08rem),
    linear-gradient(90deg, @table-customization-select-view-option-bg 0, @table-customization-select-view-option-bg 100%),
    linear-gradient(90deg, @table-customization-select-view-option-bg .03rem, transparent .03rem, transparent .08rem, @table-customization-select-view-option-bg .08rem),
    linear-gradient(90deg, @table-customization-select-view-option-bg 0, @table-customization-select-view-option-bg 100%),
    linear-gradient(90deg, @table-customization-select-view-option-bg .03rem, transparent .03rem, transparent .08rem, @table-customization-select-view-option-bg .08rem);
    background-position: .06rem .05rem, .02rem .13rem, .06rem .19rem, .02rem .27rem, .06rem .32rem;
    background-size: .56rem .03rem, .67rem .01rem, .56rem .03rem, .67rem .01rem, .56rem .03rem;
  }

  &-parity-row {
    background-image: linear-gradient(90deg, @table-customization-select-view-option-bg .03rem, transparent .03rem, transparent .08rem, @table-customization-select-view-option-bg .08rem),
    linear-gradient(90deg, @table-customization-select-view-option-bg 0, @table-customization-select-view-option-bg 100%),
    linear-gradient(90deg, #F0F0F0 0, #F0F0F0 100%),
    linear-gradient(90deg, #F0F0F0 0, #F0F0F0 .03rem, @table-customization-select-view-option-bg .07rem, #F0F0F0 .07rem, #F0F0F0 .12rem, @table-customization-select-view-option-bg .12rem, @table-customization-select-view-option-bg .6rem, #F0F0F0 .6rem),
    linear-gradient(90deg, #F0F0F0 0, #F0F0F0 100%),
    linear-gradient(90deg, @table-customization-select-view-option-bg 0, @table-customization-select-view-option-bg 100%),
    linear-gradient(90deg, @table-customization-select-view-option-bg .03rem, transparent .03rem, transparent .08rem, @table-customization-select-view-option-bg .08rem);
    background-position: .06rem .05rem, .02rem .13rem, .02rem .14rem, .02rem .19rem, .02rem .22rem, .02rem .27rem, .06rem .32rem;
    background-size: .56rem .03rem, .67rem .01rem, .67rem .05rem, .67rem .03rem, .67rem .06rem, .67rem .01rem, .56rem .03rem;
  }

  .@{c7n-prefix}-collapse {
    &-header {
      padding: .12rem .2rem !important;
    }

    &-content {
      padding: 0 .2rem;
    }
  }
}
