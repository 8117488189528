@import '../../style/mixins/index';

@tree-default-open-icon: '\e606';
@tree-showline-open-icon: '\e621';
@tree-showline-close-icon: '\e645';
@tree-doc-icon: '\e664';
@tree-prefix-cls: ~'@{c7n-prefix}-tree';
@tree-node-prefix-cls: ~'@{tree-prefix-cls}-treenode';

.antTreeSwitcherIcon(@type: 'tree-default-open-icon') {
  &::after {
    .iconfont-size-under-12px(0.07rem);
    display: inline-block;
    .iconfont-font(@type);
    font-weight: bold;
    transition: transform 0.3s;
  }
}

.antTreeShowLineIcon(@type) {
  &::after {
    .iconfont-size-under-12px(0.12rem);
    display: inline-block;
    font-weight: normal;
    vertical-align: baseline;
    transition: transform 0.3s;
    .iconfont-font(@type);
  }
}

.@{tree-node-prefix-cls}-leaf-last {
  .@{tree-prefix-cls}-switcher {
    &-leaf-line {
      &::before {
        top: auto !important;
        bottom: auto !important;
        height: @tree-title-height - 10px !important;
      }
    }
  }
}
