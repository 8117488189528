@import '../themes/default';

.reset-component() {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: @text-color;
  font-size: @font-size-base;
  font-family: @font-family;
  line-height: @line-height-base;
  list-style: none;
}
