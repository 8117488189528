@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';

@field-prefix-cls: ~'@{c7n-pro-prefix}-field';

.@{field-prefix-cls} {
  display: inline-block;
  width: 100%;

  &-label,
  &-label-vertical {
    overflow: hidden;
    line-height: @label-line-height;
    white-space: @label-white-space;
    text-overflow: ellipsis;
    vertical-align: middle;

    label {
      display: inline;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }
  }

  &-label {
    padding: @label-wrapper-padding;
  }

  &-wrapper {
    padding: @form-item-wrapper-padding;
  }

  &-label-vertical {
    display: block;
    padding-top: @label-vertical-wrapper-padding-vertical;
    padding-bottom: @label-vertical-wrapper-padding-vertical;
    direction: ltr;
    text-align: left;

    & + .@{field-prefix-cls}-wrapper {
      padding-top: 0;
    }
  }

  &-label::after {
    display: inline-block;
    width: 0.08rem;
    margin-left: 0.04rem;
    color: @label-required-color;
    font-family: SimSun, sans-serif;
    line-height: 1;
    vertical-align: middle;
    content: ' ';
  }

  &-required&-label::after {
    content: '*';
  }

  &-required&-label&-label-output::after {
    content: '';
  }

  &-required&-label-useColon::after,
  &-label&-label-useColon::after {
    position: relative;
    width: 0;
    margin: 0 0.1rem 0 0.01rem;
    vertical-align: baseline;
    content: ':';
  }

  &-label&-label-useColon::after {
    top: -0.01rem;
    color: @label-colon-color;
  }

  &-label-vertical&-label&-label-useColon {
    width: auto;
  }

  &-required&-label&-label-useColon::before {
    display: inline-block;
    margin-right: 0.02rem;
    color: @label-required-color;
    line-height: 1;
    content: '*';
  }

  &-help {
    color: @field-help-color;
    font-size: small;
    // 帮助信息相对输入框应该另起一行
    // display: inline-block;
    word-break: break-all;
  }

  &-output {
    padding-top: @label-output-wrapper-padding-vertical;
    padding-bottom: @label-output-wrapper-padding-vertical;
  }

  &-label-output {
    padding-top: @form-item-wrapper-output-padding-vertical;
    padding-bottom: @form-item-wrapper-output-padding-vertical;
  }

  &-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &-col {
    position: relative;
    flex-grow: 0;
    max-width: 100%;
    min-height: 1px;

    &-control {
      flex: 1;
    }
  }

  &-col &-label {
    display: inline-block;
  }
}
