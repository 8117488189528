@field-prefix-cls: ~'@{c7n-pro-prefix}-field';
@field-label-prefix-cls: ~'@{field-prefix-cls}-label';

.validation() {
  &-invalid &,
  &-invalid label:hover & {
    color: @error-color;
    border-color: @input-error-border-color;
    opacity: 1;
  }

  &-invalid&-wrapper {
    background-color: @input-error-bg;
  }

  &-invalid &-clear-button {
    background: linear-gradient(to right,
    rgba(255, 255, 255, 0),
    @input-error-bg 20%,
    @input-error-bg) !important;
  }

  // &-invalid &:focus {
  //   box-shadow: 0 0 @outline-blur-size @outline-width fade(@error-color, 20%);
  // }
}

.float-label(@prefix-width: @input-icon-width-base) {
  &-float-label .@{field-label-prefix-cls} {
    &-wrapper {
      position: absolute;
      top: 0;
      left: @input-padding-horizontal-base;
      max-width: 98%;
      height: 0;
      margin-left: -0.05rem;
      padding: 0 0.05rem;
      border-top: 0.02rem @border-style-base @component-background;
      transform-origin: 0.1rem -230%;
      transition: all 0.2s @ease-out;
    }

    &::after {
      width: 0;
    }

    padding: 0;
    color: @float-label-color;
    line-height: @float-label-line-height;
    white-space: nowrap;
    transform-origin: inherit;
    transition: inherit;
  }
  &-float-label&-focused .@{field-label-prefix-cls} {
    color: @outline-color;
  }
  &-float-label&-invalid .@{field-label-prefix-cls} {
    color: @error-color;
  }
  &-float-label .@{field-label-prefix-cls}-wrapper,
  &-float-label &:focus + .@{field-label-prefix-cls}-wrapper {
    transform: scaleX(0.8);
  }
  &-float-label &:-webkit-autofill + .@{field-label-prefix-cls}-wrapper {
    transform: scaleX(0.8);
  }
  &-float-label .@{field-label-prefix-cls},
  &-float-label&-focused .@{field-label-prefix-cls},
  &-float-label &:-webkit-autofill + .@{field-label-prefix-cls}-wrapper .@{field-label-prefix-cls} {
    font-weight: @float-label-font-weight;
    font-size: @float-label-font-size;
    transform: scaleY(0.8);
  }
  &-float-label:not(&-multiple) & {
    height: @float-label-height-base;
    border-radius: @input-float-border-radius;
  }
  &-float-label&-sm:not(&-multiple) & {
    height: @float-label-height-sm;
  }
  &-float-label&-sm .@{field-label-prefix-cls} {
    line-height: @float-label-line-height-sm;
  }
  &-float-label&-lg:not(&-multiple) & {
    height: @float-label-height-lg;
  }
  &-float-label&-lg .@{field-label-prefix-cls} {
    line-height: @float-label-line-height-lg;
  }
  &-float-label&-lg:not(&-multiple) textarea&,
  &-float-label&-sm:not(&-multiple) textarea&,
  &-float-label:not(&-multiple) textarea&,
  &-float-label textarea& {
    height: auto;
  }
  &-float-label&-required-colors {
    background-color: @input-float-required-bg;
  }
  &-float-label&-invalid {
    background-color: @input-float-error-bg;
  }
  &-float-label&-disabled {
    background-color: @input-float-disabled-bg;
  }
  &-float-label&-required .@{field-label-prefix-cls}::after {
    width: 0.08rem;
  }
  &-empty:not(&-focused) {
    .@{field-label-prefix-cls} {
      &-wrapper {
        border-top-color: transparent;
        transform: none;
      }

      font-weight: inherit;
      font-size: inherit;
      transform: none;
      pointer-events: none;
    }
  }
  &-float-label & > ul {
    margin-top: 0.06rem;
    margin-bottom: @input-float-border-active-width;
    margin-left: 0.03rem;

    > li {
      display: inline-block;
      max-width: 99%;
      height: 0.2rem;
      margin-bottom: 0.02rem;
      line-height: 0.2rem;
      vertical-align: middle;
    }
  }
  &-float-label &-multiple-block {
    display: inline-block;
    max-width: 99%;
    margin: 0 @input-float-border-active-width;
    padding: 0 @input-float-border-active-width 0 0.08rem;
    overflow: hidden;
    color: @tag-default-color;
    line-height: @line-height-base;
    white-space: nowrap;
    background-color: @tag-default-bg;
    border-radius: 0.12rem;

    > div {
      display: inline-block;
      max-width: 100%;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: top;

      &:not(:last-child) {
        max-width: ~'calc(100% - .24rem)';
      }
    }

    &-invalid {
      background-color: @error-color;
    }

    &-disabled {
      padding-right: 0.1rem;
      background-color: @disabled-color;
      cursor: not-allowed;
    }

    cursor: default;

    .@{iconfont-css-prefix} {
      height: 100%;
      margin-left: 0.08rem;
      color: @tag-multiple-color;
      font-size: 0.16rem;
      line-height: 0.2rem;
      vertical-align: top;
      cursor: pointer;
    }
  }
  &-prefix-button&-float-label .@{field-label-prefix-cls}-wrapper {
    left: @prefix-width;
  }
}
