@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';

.@{c7n-pro-prefix}-pagination {
  line-height: 0.3rem;

  > * {
    vertical-align: middle;
  }

  &-size-changer {
    width: 0.7rem;

    .@{c7n-pro-prefix}-select {
      text-align: right;
    }
  }

  &-size-changer:not(&-size-editable) .@{c7n-pro-prefix}-select,
  &-size-changer&-size-editable .@{c7n-pro-prefix}-select:not(:hover):not(:focus) {
    border-color: transparent;
  }

  &-page-info {
    margin: 0 0.2rem;
  }

  &-pager {
    height: @btn-circle-size;
  }

  &-quick-jumper {
    display: inline-block;
    height: 0.3rem;
    line-height: 0.3rem;

    input {
      position: relative;
      display: inline-block;
      width: 0.5rem;
      height: 0.3rem;
      margin: 0 0.08rem;
      padding: 0.04rem 0.11rem;
      color: rgba(0, 0, 0, 0.65);
      line-height: 1.5;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }

  &-simple-pager {
    display: inline-block;
    height: 0.3rem;
    margin-right: 0.08rem;
    vertical-align: middle;

    input {
      position: relative;
      display: inline-block;
      width: 0.5rem;
      height: 0.3rem;
      margin: 0 0.08rem;
      padding: 0.04rem 0.11rem;
      color: rgba(0, 0, 0, 0.65);
      line-height: 1.5;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }

  &-go-button {
    margin-left: 0.1rem;
  }
}
