@import '../../style/themes/default';
@import '../../style/mixins/index';

@tab-prefix-cls: ~'@{c7n-prefix}-tabs';

// card style
.@{tab-prefix-cls} {
  &&-card > &-bar &-nav-container {
    height: @tabs-card-height;
  }
  &&-card > &-bar &-ink-bar {
    visibility: hidden;
  }
  &&-card > &-bar &-tab {
    margin: 0;
    margin-right: 0.02rem;
    padding: 0 0.16rem;
    line-height: @tabs-card-height - 0.02rem;
    background: @tabs-card-head-background;
    border: @border-width-base @border-style-base @border-color-split;
    border-bottom: 0;
    border-radius: @border-radius-base @border-radius-base 0 0;
    transition: all 0.3s @ease-in-out;
  }
  &&-card > &-bar &-tab-active {
    padding-bottom: 0.01rem;
    color: @tabs-card-active-color;
    background: @component-background;
    border-color: @border-color-split;
  }
  &&-card > &-bar &-tab-inactive {
    padding: 0;
  }
  &&-card > &-bar &-nav-wrap {
    margin-bottom: 0;
  }
  &&-card > &-bar &-tab .@{iconfont-css-prefix}-close {
    width: 0.16rem;
    height: 0.16rem;
    height: @font-size-base;
    margin-right: -0.05rem;
    margin-left: 0.03rem;
    overflow: hidden;
    color: @text-color-secondary;
    font-size: @font-size-sm;
    vertical-align: middle;
    transition: all 0.3s;
    &:hover {
      color: @heading-color;
    }
  }

  &&-card &-content > &-tabpane,
  &&-editable-card &-content > &-tabpane {
    transition: none !important;
    &-inactive {
      overflow: hidden;
    }
  }

  &&-card > &-bar &-tab:hover .@{iconfont-css-prefix}-close {
    opacity: 1;
  }

  &-extra-content {
    line-height: @tabs-card-height;

    .@{tab-prefix-cls}-new-tab {
      width: 0.2rem;
      height: 0.2rem;
      color: @text-color;
      font-size: 0.12rem;
      line-height: 0.2rem;
      text-align: center;
      border: @border-width-base @border-style-base @border-color-split;
      border-radius: @border-radius-sm;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: @tabs-card-active-color;
        border-color: @tabs-card-active-color;
      }
    }
  }
  &-vertical&-card > .@{tab-prefix-cls}-bar {
    .@{tab-prefix-cls}-nav-container {
      height: auto;
    }
    .@{tab-prefix-cls}-tab {
      margin-bottom: 0.08rem;
      border-bottom: @border-width-base @border-style-base @border-color-split;
      &-active {
        padding-bottom: 0.04rem;
      }
      &:last-child {
        margin-bottom: 0.08rem;
      }
    }
    .@{tab-prefix-cls}-new-tab {
      width: 90%;
    }
  }

  &-vertical&-card&-left > .@{tab-prefix-cls}-bar {
    .@{tab-prefix-cls}-nav-wrap {
      margin-right: 0;
    }
    .@{tab-prefix-cls}-tab {
      margin-right: 0.01rem;
      border-right: 0;
      border-radius: @border-radius-base 0 0 @border-radius-base;
      &-active {
        margin-right: -0.01rem;
        padding-right: 0.18rem;
      }
    }
  }

  &-vertical&-card&-right > .@{tab-prefix-cls}-bar {
    .@{tab-prefix-cls}-nav-wrap {
      margin-left: 0;
    }
    .@{tab-prefix-cls}-tab {
      margin-left: 0.01rem;
      border-left: 0;
      border-radius: 0 @border-radius-base @border-radius-base 0;
      &-active {
        margin-left: -0.01rem;
        padding-left: 0.18rem;
      }
    }
  }
  &&-card&-bottom > &-bar &-tab {
    border-top: 0;
    border-bottom: @border-width-base @border-style-base @border-color-split;
    border-radius: 0 0 @border-radius-base @border-radius-base;
  }

  &&-card&-bottom > &-bar &-tab-active {
    padding-top: 0.01rem;
    padding-bottom: 0;
    color: @primary-color;
  }
}
