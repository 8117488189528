@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import '../../text-field/style/mixin';
@import 'mixin';

@output-prefix-cls: ~'@{c7n-pro-prefix}-output';

.@{output-prefix-cls} {
  line-height: @output-line-height;
  word-break: @output-word-break;

  &-invalid {
    color: @error-color;
    background-color: @error-bg-color;
    border: @border-width-base @border-style-base @error-color;
    border-radius: @border-radius-base;
  }

  &-multiple-block {
    .tag-block;
    vertical-align: text-top;
  }

  &:empty::before {
    display: inline-block;
    width: 0;
    content: '';
  }

  &-highlight {
    border-color: @input-highlight-border-color;
  }
  .multi;
}
