@media screen and (max-width: @screen-md) {
  .@{backtop-prefix-cls} {
    right: .6rem;
  }
}

@media screen and (max-width: @screen-xs) {
  .@{backtop-prefix-cls} {
    right: .2rem;
  }
}
