@import '../../../components/style/themes/default';

@filter-list-prefix-cls: ~'@{c7n-pro-prefix}-table-filter-list';

.@{filter-list-prefix-cls} {
  display: flex;
  flex-direction: column;
  width: 2.8rem;
  max-height: 4.1rem;
  font-size: 0.12rem;
  background: white;
  box-shadow: 0 0.01rem 0.08rem 0 rgba(0, 0, 0, 0.12), 0 0.02rem 0.02rem 0 rgba(0, 0, 0, 0.14), 0 0.02rem 0.01rem -0.02rem rgba(0, 0, 0, 0.2);

  &-search {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 0.36rem;
    padding: 0 0.06rem;
    color: #A4A4A4;
    font-size: 0.12rem;
    border-bottom: 0.01rem solid rgba(0, 0, 0, 0.12);

    input {
      border: none;
    }

    &-divide {
      width: 0.01rem;
      height: 0.12rem;
      margin: 0 0.08rem;
      background-color: #DBE1F1;
    }

    &-action {
      margin-right: 0.08rem;
      color: @primary-color;
      cursor: pointer;
    }

    &-selected {
      color: rgba(124,133,155,0.65);
    }

    &-items {
      display: inline-block;
      width: 0.16rem;
      text-align: center;
    }
  }

  &-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 0.4rem;
    padding: 0 0.14rem;
    border-bottom: 0.01rem solid rgba(0, 0, 0, 0.12);
  }

  &-content {
    flex: 1;
    padding: 0.08rem 0.14rem;
    overflow: auto;
  }

  &-title {
    margin-top: 0.1rem;
    margin-bottom: 0.05rem;
    color: rgba(0, 0, 0, 0.65);
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
  }

  &-item {
    width: 100%;
    margin: 0.06rem 0;
  }

  .@{c7n-pro-prefix}-checkbox:disabled + .@{c7n-pro-prefix}-checkbox-inner {
    background-color: #f5f5f5 !important;
  }
}
