@import '../../../components/style/themes/default';
@import '../../text-field/style/mixin';

@textarea-prefix: ~'@{c7n-pro-prefix}-textarea';

.@{textarea-prefix} {
  min-height: .3rem;
  .input;

  &,
  &-placeholder {
    padding: .07rem @input-padding-horizontal;
  }

  &-invalid&-wrapper {
    background-color: transparent;
  }

  &-required {
    background-color: transparent;

    & .@{textarea-prefix} {
      background-color: @input-required-bg;
    }

    & .@{textarea-prefix}:disabled {
      background-color: @input-disabled-bg;
    }
  }

  &-invalid {
    & .@{textarea-prefix} {
      background-color: @error-bg-color;
    }
  }

  &-invalid& {
    border-color: @error-color;
  }

  &-float-label&-required {
    background-color: transparent;

    & .@{textarea-prefix} {
      background-color: transparent;
    }
  }
}
