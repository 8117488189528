@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import '../../radio/style/mixin';
@import 'mixin';

@pro-checkbox-prefix-cls: ~'@{c7n-pro-prefix}-checkbox';

.@{pro-checkbox-prefix-cls} {
  .radio-btn;

  &-wrapper,
  &-wrapper.@{field-prefix-cls} {
    display: inline-flex;
  }

  &-inner {
    position: relative;
    display: inline-block;
    width: @checkbox-size;
    height: @checkbox-size;
    font-size: @font-size-base !important;
    vertical-align: middle;
    background-color: #fff;
    border: @checkbox-border-width @border-style-base @checkbox-border-color;
    border-radius: @border-radius-sm;
    border-collapse: separate;
    transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1),
      background-color 90ms cubic-bezier(0, 0, 0.2, 0.1);

    .uncheck-left;

    & + span {
      padding-right: 0.08rem;
      padding-left: 0.08rem;
      vertical-align: middle;
    }
  }

  &:hover + &-inner,
  &-wrapper:hover &-inner {
    border-color: @primary-7;
  }
  &:focus + &-inner {
    border-color: @primary-7;
    box-shadow: 0 0 @outline-blur-size @outline-width fade(@outline-color, 20%);
  }

  &:checked + &-inner,
  &-indeterminate &-inner {
    background-color: @primary-color !important;
    border-color: @primary-color;
    .check;
  }
  &:hover &:checked + &-inner,
  &-indeterminate:hover &-inner,
  &:focus:checked + &-inner {
    background-color: @primary-7;
    border-color: @primary-7;
  }

  &-indeterminate &-inner {
    .indeterminate;
  }

  &:disabled + &-inner {
    color: @disabled-color;
    background-color: @input-disabled-bg !important;
    border-color: @disabled-color !important;
    &::after {
      border-color: @disabled-color !important;
    }
  }

  &-label {
    display: inline-block;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-button {
    padding: 0 0.15rem;
    line-height: 0.3rem;
    .@{pro-checkbox-prefix-cls}-inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;
      height: auto;
      border-radius: @border-radius-base;
      &::after {
        display: none;
      }
      & + span {
        position: relative;
        z-index: 1;
        padding: 0;
      }
    }
    .@{pro-checkbox-prefix-cls}:checked + .@{pro-checkbox-prefix-cls}-inner + span {
      color: #fff;
    }
    &:hover .@{pro-checkbox-prefix-cls}-inner + span {
      color: @primary-color;
    }
  }
  &-button + &-button {
    margin-left: @btn-group-spacing;
  }
  &-float-label .@{field-label-prefix-cls}-wrapper {
    left: 0;
    transform-origin: @float-label-transform-origin-high;
  }
}
