@import '../../style/themes/default';
@import '../../style/mixins/index';
@import '../../input/style/mixin';

@select-prefix-cls: ~'@{c7n-prefix}-select';
@checkbox-prefix-cls: ~'@{c7n-prefix}-checkbox';

.@{select-prefix-cls} {
  .reset-component;
  position: relative;
  display: inline-block;

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul > li > a {
    padding: 0;
    background-color: @component-background;
  }

  .label;

  &-has-border&::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border: @border-width-base @border-style-base @input-border-color;
    border-radius: 0.04rem;
    content: ' ';
  }

  &-has-border&:hover::before {
    border-color: @input-color;
  }

  &-has-border&-focused&::before,
  .has-error &::before {
    border-width: 0.02rem;
  }
  &-has-border&-focused&::before {
    border-color: @primary-color;
  }
  .has-error &-has-border&::before {
    border-color: @error-color;
  }
  &-has-border&-disabled::before {
    border-color: @input-border-color !important;
    border-style: dashed;
  }

  &-arrow {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    transition: opacity 0.15s ease;
  }

  &-clear {
    position: absolute !important;
    top: 0;
    right: 0.2rem;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.15s ease;
  }

  &:hover &-clear {
    opacity: 1;
  }

  &-multiple&-open {
    .@{select-prefix-cls}-selection-label {
      margin-top: 0 !important;
      color: @primary-color;
      transform: scale(0.75) translateY(-0.2rem) translateZ(0.00001rem);
      transition: transform 300ms @ease-out, color 100ms @ease-out, background-color 300ms @ease-out;
    }
  }

  &&-focused {
    .@{select-prefix-cls}-selection {
      &-label {
        margin-top: 0 !important;
        color: @primary-color;
        transform: scale(0.75) translateY(-0.2rem) translateZ(0.00001rem);
        transition: transform 300ms @ease-out, color 100ms @ease-out,
          background-color 300ms @ease-out;
      }
      &__placeholder {
        display: inline-block;
      }
    }
  }

  &&-has-value {
    .@{select-prefix-cls}-selection {
      &-label {
        margin-top: 0 !important;
        transform: scale(0.75) translateY(-0.2rem) translateZ(0.00001rem);
      }
      &__placeholder {
        display: none;
      }
    }
  }
  &-selection {
    position: relative;
    display: block;
    align-items: center;
    box-sizing: content-box;
    width: 100%;
    min-width: 0.5rem;
    max-width: 100%;
    color: #000;
    letter-spacing: 0.01em;
    background-color: @input-bg;
    border: none;
    // strange align fix for chrome but works
    // https://gw.alipayobjects.com/zos/rmsportal/VFTfKXJuogBAXcvfAUWJ.gif
    border-top-width: @border-width-base + 0.0002rem;
    outline: none;
    cursor: pointer;
    transition: all 0.3s @ease-in-out;
    user-select: none;

    &--single {
      position: relative;
      cursor: pointer;
    }

    &__rendered {
      position: relative;
      display: block;
      width: 100%;
      height: 0.36rem;
      padding-right: 0.24rem;
      padding-left: @input-padding-horizontal;
      line-height: 0.34rem;
    }

    &-selected-value {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
      &::after {
        display: inline-block;
        visibility: hidden;
        content: '';
      }
    }

    &-label {
      position: absolute;
      top: 0.15rem;
      left: 0;
      z-index: 1;
      width: 100%;
      margin-top: 0.02rem;
      color: @label-color;
      transform-origin: 0 0;
      transition: transform 400ms @ease-out, color 400ms @ease-out, background-color 400ms @ease-out;
      pointer-events: none;
      .is-required &::after {
        display: inline-block;
        margin-left: 0.04rem;
        color: @label-required-color;
        font-size: @font-size-base;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
        // .@{form-prefix-cls}-hide-required-mark & {
        //   display: none;
        // }
      }
    }
  }

  &-disabled {
    color: @input-disabled-color;

    .@{select-prefix-cls}-selection {
      color: @input-disabled-color;
      cursor: not-allowed;
      &:hover,
      &:focus,
      &:active {
        border-color: @border-color-base;
        box-shadow: none;
      }
      &-label,
      &__placeholder {
        color: @input-disabled-color;
      }
      &__choice__remove {
        display: none;
        color: @input-disabled-color;
        cursor: default;
        &:hover {
          color: @input-disabled-color;
        }
      }
    }

    .@{select-prefix-cls}-clear {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }

    &.@{select-prefix-cls}-tags,
    &.@{select-prefix-cls}-multiple {
      .@{select-prefix-cls}-selection--multiple &-selection__choice {
        color: @tag-default-color;
        background: rgba(0, 0, 0, 0.26);
      }
    }
  }

  &-lg {
    font-size: @font-size-lg;
    .@{select-prefix-cls}-selection__rendered {
      height: @input-height-lg;
      line-height: @input-height-lg - 0.02rem;
    }
    i.@{iconfont-css-prefix} {
      font-size: 0.24rem;
    }
    &.@{select-prefix-cls}-tags,
    &.@{select-prefix-cls}-multiple {
      .@{select-prefix-cls}-selection__rendered {
        min-height: @input-height-lg;
      }
      .@{select-prefix-cls}-selection--multiple {
        > ul > li,
        .@{select-prefix-cls}-selection__rendered > ul > li,
        .@{select-prefix-cls}-selection__choice__remove > i.@{iconfont-css-prefix} {
          height: @input-height-lg - 0.1rem;
          line-height: @input-height-lg - 0.1rem;
        }
      }
    }
  }

  &-sm {
    .@{select-prefix-cls}-selection__rendered {
      height: @input-height-sm;
      line-height: @input-height-sm - 0.02rem;
    }
    i.@{iconfont-css-prefix} {
      font-size: 0.16rem;
    }
    &.@{select-prefix-cls}-tags,
    &.@{select-prefix-cls}-multiple {
      .@{select-prefix-cls}-selection__rendered {
        min-height: @input-height-sm;
      }
      .@{select-prefix-cls}-selection--multiple {
        > ul > li,
        .@{select-prefix-cls}-selection__rendered > ul > li,
        .@{select-prefix-cls}-selection__choice__remove > i.@{iconfont-css-prefix} {
          height: @input-height-sm - 0.1rem;
          line-height: @input-height-sm - 0.1rem;
        }
      }
    }
  }

  &-search__field__wrap {
    position: relative;
    display: inline-block;
  }

  &-selection__placeholder,
  &-search__field__placeholder {
    // for TreeSelect compatibility
    position: absolute;
    left: @control-padding-horizontal;
    z-index: 1;
    width: 100%;
    margin-top: 0.02rem;
    color: @input-placeholder-color;
    pointer-events: none;
  }

  &-search__field__placeholder {
    left: @control-padding-horizontal;
  }

  &-search__field__mirror {
    position: absolute;
    top: 0;
    left: -99.99rem;
    white-space: pre;
    pointer-events: none;
  }

  &-search--inline {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .@{select-prefix-cls}-search__field__wrap {
      width: 100%;
      height: 100%;
    }

    .@{select-prefix-cls}-search__field {
      //display: none;
      width: 100%;
      height: 100%;
      font-size: 100%;
      background: transparent;
      border-width: 0;
      border-radius: @border-radius-base;
      outline: 0;
    }

    > i {
      float: right;
    }
  }

  &-tags,
  &-multiple {
    .@{select-prefix-cls}-selection--multiple {
      cursor: text;
      .clearfix;

      .@{select-prefix-cls}-search--inline {
        position: static;
        float: left;
        width: auto;

        max-width: 100%;
        padding: 0;
        .@{select-prefix-cls}-search__field {
          width: 0.75em;
          max-width: 100%;
        }
      }

      .@{select-prefix-cls}-selection-label {
        margin-top: 0.05rem;
      }

      .@{select-prefix-cls}-selection__rendered {
        height: auto;
        min-height: 0.36rem;
        padding: 0.08rem 0.04rem 0 0.04rem;
      }

      .@{select-prefix-cls}-selection__placeholder,
      .@{select-prefix-cls}-search__field__placeholder {
        // for TreeSelect compatibility
        margin-top: 0;
        line-height: 1.5;
      }

      > ul > li,
      .@{select-prefix-cls}-selection__rendered > ul > li {
        // for tree-select
        box-sizing: content-box;
        height: @input-height-base - 0.1rem;
        line-height: @input-height-base - 0.1rem;
      }

      .@{select-prefix-cls}-selection__choice {
        position: relative;
        float: left;
        max-width: 99%;
        margin: 0 0.08rem 0.03rem 0;
        overflow: hidden;
        border-radius: @border-radius-lg;
        cursor: default;
        transition: padding 0.3s @ease-in-out;
        &__disabled {
          padding: 0 0.1rem;
        }
        &__content {
          float: left;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: margin 0.3s @ease-in-out;
          &::after {
            content: none;
          }
        }
        &__remove {
          margin-left: 0.08rem;
          color: @tag-default-color;
          cursor: pointer;
          > i.@{iconfont-css-prefix} {
            float: right;
            height: @input-height-base - 0.1rem;
            color: @tag-multiple-color;
            font-size: 0.16rem;
            line-height: @input-height-base - 0.1rem;
          }
        }
      }
    }
  }

  &-multiple {
    .@{select-prefix-cls}-selection--multiple {
      .@{select-prefix-cls}-selection__choice {
        padding: 0 0.02rem 0 0.08rem;
        background-color: rgba(0, 0, 0, 0.08);
        &__remove
        > i.@{iconfont-css-prefix} {
          color: rgba(0, 0, 0, 0.54);
        }
        &.@{select-prefix-cls}-selection__max {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
    &.@{select-prefix-cls}-allow-clear {
      .@{select-prefix-cls}-selection__rendered {
        padding-right: 0.44rem;
        .@{select-prefix-cls}-clear {
          right: 0.2rem;
        }
      }
    }
  }

  &-tags {
    .@{select-prefix-cls}-selection--multiple {
      .@{select-prefix-cls}-selection__choice {
        padding: 0 0.02rem 0 0.08rem;
        color: @tag-default-color;
        background-color: @tag-default-bg;
      }
    }
    &.@{select-prefix-cls}-allow-clear {
      .@{select-prefix-cls}-selection__rendered {
        padding-right: 0.24rem;
        .@{select-prefix-cls}-clear {
          right: 0;
        }
      }
    }
    .@{select-prefix-cls}-search__field {
      display: inline-block;
    }
  }

  &-open {
    .@{select-prefix-cls}-arrow::before {
      transform: rotate(180deg);
    }
    .@{select-prefix-cls}-selection {
      .active();
    }
  }

  &-combobox {
    .@{select-prefix-cls}-arrow {
      display: none;
    }
    .@{select-prefix-cls}-clear {
      right: 0;
    }
    &.@{select-prefix-cls}-allow-clear {
      .@{select-prefix-cls}-selection__rendered {
        padding-right: 0.24rem;
      }
    }

    .@{select-prefix-cls}-search--inline {
      width: 100%;
      overflow: inherit;
      text-overflow: inherit;
    }

    .@{select-prefix-cls}-search__field__wrap {
      width: 100%;
      height: 100%;
    }

    .@{select-prefix-cls}-search__field {
      position: relative;
      z-index: 1;
      display: inline-block;
      width: 100%;
      height: 100%;
      box-shadow: none;
      transition: all 0.3s @ease-in-out;
    }

    .@{select-prefix-cls}-selection__placeholder,
    .@{select-prefix-cls}-search__field__placeholder {
      // for TreeSelect compatibility
      margin-top: 0;
    }
  }
}

.@{select-prefix-cls}-dropdown {
  .reset-component;

  &.slide-up-enter.slide-up-enter-active&-placement-bottomLeft,
  &.slide-up-appear.slide-up-appear-active&-placement-bottomLeft {
    animation-name: antSlideUpIn;
  }

  &.slide-up-enter.slide-up-enter-active&-placement-topLeft,
  &.slide-up-appear.slide-up-appear-active&-placement-topLeft {
    animation-name: antSlideDownIn;
  }

  &.slide-up-leave.slide-up-leave-active&-placement-bottomLeft {
    animation-name: antSlideUpOut;
  }

  &.slide-up-leave.slide-up-leave-active&-placement-topLeft {
    animation-name: antSlideDownOut;
  }

  &-hidden {
    display: none;
  }
  position: absolute;
  top: -99.99rem;
  left: -99.99rem;
  z-index: @zindex-dropdown;
  box-sizing: border-box;
  font-size: @font-size-base;
  background-color: @component-background;
  border-radius: @border-radius-base;
  outline: none;
  box-shadow: @shadow-4;

  &-filter {
    padding: 0.23rem 0.16rem 0.08rem 0.16rem;
    background-color: #fafafa;
    border-bottom: @border-width-base @border-style-base @border-color-base;
    .@{c7n-prefix}-input-suffix {
      height: 0.24rem;
    }
    i.@{iconfont-css-prefix} {
      color: rgba(0, 0, 0, 0.66);
    }
    &-input {
      display: inline-block;
      width: 100%;
    }
  }
  &-select-all-none {
    padding: 0.08rem 0.16rem;
    font-size: @font-size-sm;
    border-bottom: @border-width-base @border-style-base @border-color-base;
    span {
      color: @primary-color;
      cursor: pointer;
    }
    span + span {
      margin-left: 0.2rem;
    }
  }

  &-footer {
    padding: 0.16rem;
    background-color: #fafafa;
    border-top: @border-width-base @border-style-base @border-color-base;
  }

  &-menu {
    max-height: 2.5rem;
    margin-bottom: 0;
    padding: 0.08rem 0;
    overflow: auto;
    list-style: none;
    outline: none;
    &-item-group {
      padding-top: 0.04rem;
      padding-bottom: 0.04rem;
      &:not(:last-child) {
        border-bottom: @border-width-base @border-style-base @border-color-base;
      }
    }
    &-item-group-list {
      margin: 0;
      padding: 0;

      > .@{select-prefix-cls}-dropdown-menu-item {
        padding-left: 0.2rem;
      }
    }
    &-item-group-no-title > .@{select-prefix-cls}-dropdown-menu-item {
      padding-left: @control-padding-horizontal;
    }

    &-item-group-title {
      height: 0.32rem;
      padding: 0 @control-padding-horizontal;
      color: @text-color-secondary;
      font-size: @font-size-sm;
      line-height: 0.32rem;
    }

    &-item {
      position: relative;
      display: block;
      height: auto;
      padding: 0.04rem @control-padding-horizontal;
      overflow: hidden;
      color: @text-color;
      font-weight: normal;
      line-height: 0.2rem;
      letter-spacing: 0.01em;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background-color: @item-hover-bg;
      }

      &:first-child {
        border-radius: @border-radius-base @border-radius-base 0 0;
      }

      &:last-child {
        border-radius: 0 0 @border-radius-base @border-radius-base;
      }

      &-disabled {
        color: @input-disabled-color;
        cursor: not-allowed;

        &:hover {
          color: @input-disabled-color;
          background-color: @component-background;
          cursor: not-allowed;
        }
      }

      &-selected {
        background-color: @item-focus-bg;
      }

      &-active {
        background-color: @item-focus-bg;
      }

      &-divider {
        height: 0.01rem;
        margin: 0.01rem 0;
        overflow: hidden;
        line-height: 0;
        background-color: @border-color-split;
      }
    }
  }

  &&--multiple {
    .@{select-prefix-cls}-dropdown-menu-item {
      .@{checkbox-prefix-cls}-wrapper {
        margin-right: 0.08rem;
        user-select: none;
      }

      &:hover {
        background-color: @item-hover-bg;
      }

      &-active,
      &:active {
        // background-color: @menu-item-active-bg;
        background-color: @item-hover-bg;
      }

      &:focus {
        // background-color: @menu-item-active-bg;
        background-color: @item-hover-bg;
      }
    }

    .@{select-prefix-cls}-dropdown-menu-item-selected {
      background-color: transparent;
    }
  }

  &-container-open,
  &-open {
    .@{select-prefix-cls}-dropdown {
      display: block;
    }
  }
}
