@import '../../style/themes/default';
@import '../../style/mixins/index';

@alert-prefix-cls: ~'@{c7n-prefix}-alert';

@alert-message-color: @heading-color;
@alert-text-color: @text-color;

.@{alert-prefix-cls} {
  .reset-component;
  position: relative;
  padding: 0.08rem 0.15rem 0.08rem 0.37rem;
  border-radius: @border-radius-base;

  &&-no-icon {
    padding: 0.08rem 0.15rem;
  }

  &-icon {
    position: absolute;
    top: 0.05rem + @font-size-base * @line-height-base / 2 - @font-size-base / 2;
    left: 0.16rem;
  }

  &-description {
    display: none;
    font-size: @font-size-base;
    line-height: 0.22rem;
  }

  &-success {
    background-color: ~`colorPalette('@{success-color}', 1) `;
    border: @border-width-base @border-style-base ~`colorPalette('@{success-color}', 3) `;
    .@{alert-prefix-cls}-icon {
      color: @success-color;
    }
  }

  &-info {
    background-color: ~`colorPalette('@{info-color}', 1) `;
    border: @border-width-base @border-style-base ~`colorPalette('@{info-color}', 3) `;
    .@{alert-prefix-cls}-icon {
      color: @info-color;
    }
  }

  &-warning {
    background-color: ~`colorPalette('@{warning-color}', 1) `;
    border: @border-width-base @border-style-base ~`colorPalette('@{warning-color}', 3) `;
    .@{alert-prefix-cls}-icon {
      color: @warning-color;
    }
  }

  &-error {
    background-color: ~`colorPalette('@{error-color}', 1) `;
    border: @border-width-base @border-style-base ~`colorPalette('@{error-color}', 3) `;
    .@{alert-prefix-cls}-icon {
      color: @error-color;
    }
  }

  &-close-icon {
    position: absolute;
    top: 0.08rem;
    right: 0.16rem;
    overflow: hidden;
    font-size: @font-size-sm;
    line-height: 0.22rem;
    cursor: pointer;

    .@{iconfont-css-prefix}-cross {
      color: @text-color-secondary;
      transition: color 0.3s;
      &:hover {
        color: #404040;
      }
    }
  }

  &-close-text {
    position: absolute;
    right: 0.16rem;
  }

  &-with-description {
    position: relative;
    padding: 0.15rem 0.15rem 0.15rem 0.64rem;
    color: @text-color;
    line-height: @line-height-base;
    border-radius: @border-radius-base;
  }

  &-with-description&-no-icon {
    padding: 0.15rem;
  }

  &-with-description &-icon {
    position: absolute;
    top: 0.16rem;
    left: 0.24rem;
    font-size: 0.24rem;
  }

  &-with-description &-close-icon {
    position: absolute;
    top: 0.16rem;
    right: 0.16rem;
    font-size: @font-size-base;
    cursor: pointer;
  }

  &-with-description &-message {
    display: block;
    margin-bottom: 0.04rem;
    color: @alert-message-color;
    font-size: @font-size-lg;
  }

  &-with-description &-description {
    display: block;
  }

  &&-close {
    height: 0 !important;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    transform-origin: 50% 0;
    transition: all 0.3s @ease-in-out-circ;
  }

  &-slide-up-leave {
    animation: c7nAlertSlideUpOut 0.3s @ease-in-out-circ;
    animation-fill-mode: both;
  }

  &-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
  }
}

@keyframes c7nAlertSlideUpIn {
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes c7nAlertSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
