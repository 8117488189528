@import '../../style/themes/default';
@import '../../style/mixins/index';

@badge-prefix-cls: ~'@{c7n-prefix}-badge';
@number-prefix-cls: ~'@{c7n-prefix}-scroll-number';

.@{badge-prefix-cls} {
  .reset-component;
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;

  &-count {
    position: absolute;
    top: -@badge-height / 2;
    min-width: @badge-height;
    height: @badge-height;
    padding: 0 0.06rem;
    color: #fff;
    font-weight: @badge-font-weight;
    font-size: @badge-font-size;
    line-height: @badge-height;
    white-space: nowrap;
    text-align: center;
    background: @highlight-color;
    border-radius: @badge-height / 2;
    box-shadow: @shadow-3;
    transform: translateX(-50%);
    transform-origin: -10% center;
    a,
    a:hover {
      color: #fff;
    }
  }

  &-multiple-words {
    padding: 0 0.08rem;
  }

  &-dot {
    position: absolute;
    top: -@badge-dot-size / 2;
    z-index: 10;
    width: @badge-dot-size;
    height: @badge-dot-size;
    background: @highlight-color;
    border-radius: 100%;
    box-shadow: @shadow-3;
    transform: translateX(-50%);
    transform-origin: 0 center;
  }

  &-status {
    line-height: inherit;
    vertical-align: baseline;

    &-dot {
      position: relative;
      top: -0.01rem;
      display: inline-block;
      width: @badge-status-size;
      height: @badge-status-size;
      vertical-align: middle;
      border-radius: 50%;
    }
    &-success {
      background-color: @success-color;
    }
    &-processing {
      position: relative;
      background-color: @processing-color;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: @border-width-base @border-style-base @processing-color;
        border-radius: 50%;
        animation: c7nStatusProcessing 1.2s infinite ease-in-out;
        content: '';
      }
    }
    &-default {
      background-color: @normal-color;
    }
    &-error {
      background-color: @error-color;
    }
    &-warning {
      background-color: @warning-color;
    }
    &-text {
      margin-left: 0.08rem;
      color: @text-color;
      font-size: @font-size-base;
    }
  }

  &-zoom-appear,
  &-zoom-enter {
    animation: c7nZoomBadgeIn 0.3s @ease-out-back;
    animation-fill-mode: both;
  }

  &-zoom-leave {
    animation: c7nZoomBadgeOut 0.3s @ease-in-back;
    animation-fill-mode: both;
  }

  &-not-a-wrapper .@{c7n-prefix}-scroll-number {
    position: relative;
    top: auto;
    display: block;
  }

  &-not-a-wrapper .@{badge-prefix-cls}-count {
    transform: none;
  }
}

@keyframes c7nStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

.@{number-prefix-cls} {
  overflow: hidden;
  &-only {
    display: inline-block;
    height: @badge-height;
    transition: all 0.3s @ease-in-out;
    > p {
      height: @badge-height;
      margin: 0;
    }
  }
}

@keyframes c7nZoomBadgeIn {
  0% {
    transform: scale(0) translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateX(-50%);
  }
}

@keyframes c7nZoomBadgeOut {
  0% {
    transform: scale(1) translateX(-50%);
  }
  100% {
    transform: scale(0) translateX(-50%);
    opacity: 0;
  }
}
