@keyframes loadingCircle {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: 50% 50%;
  }
}
