@import '../../style/themes/default';
@import '../../style/mixins/index';

@divider-prefix-cls: ~'@{c7n-prefix}-divider';

.@{divider-prefix-cls} {
  .reset-component;
  background: @border-color-split;

  &, /* for compatiable */
  &-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    width: 0.01rem;
    height: 0.9em;
    margin: 0 0.08rem;
    vertical-align: middle;
  }
  &-horizontal {
    display: block;
    width: 100%;
    min-width: 100%;
    height: 0.01rem;
    margin: 0.24rem 0;
  }
  &-horizontal&-with-text {
    display: table;
    margin: 0.16rem 0;
    color: @heading-color;
    font-weight: 500;
    font-size: @font-size-lg;
    white-space: nowrap;
    text-align: center;
    background: transparent;

    &::before,
    &::after {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 50%;
      border-top: @border-width-base @border-style-base @border-color-split;
      transform: translateY(50%);
      content: '';
    }
  }
  &-inner-text {
    display: inline-block;
    padding: 0 0.24rem;
  }
  &-horizontal&-with-text-left {
    display: table;
    margin: 0.16rem 0;
    color: @heading-color;
    font-weight: 500;
    font-size: @font-size-base;
    white-space: nowrap;
    text-align: center;
    background: transparent;

    &::before {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 5%;
      border-top: @border-width-base @border-style-base @border-color-split;
      transform: translateY(50%);
      content: '';
    }
    &::after {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 95%;
      border-top: @border-width-base @border-style-base @border-color-split;
      transform: translateY(50%);
      content: '';
    }
    &-inner-text {
      display: inline-block;
      padding: 0 0.1rem;
    }
  }

  &-horizontal&-with-text-right {
    display: table;
    margin: 0.16rem 0;
    color: @heading-color;
    font-weight: 500;
    font-size: @font-size-base;
    white-space: nowrap;
    text-align: center;
    background: transparent;

    &::before {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 95%;
      border-top: @border-width-base @border-style-base @border-color-split;
      transform: translateY(50%);
      content: '';
    }
    &::after {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 5%;
      border-top: @border-width-base @border-style-base @border-color-split;
      transform: translateY(50%);
      content: '';
    }
    &-inner-text {
      display: inline-block;
      padding: 0 0.1rem;
    }
  }
  &-dashed {
    background: none;
    border-top: @border-width-base dashed @border-color-split;
  }
  &-horizontal&-with-text&-dashed {
    border-top: 0;
    &::before,
    &::after {
      border-style: dashed none none;
    }
  }
}
