.show-error-motion(@className, @keyframeName, @duration: @animation-duration-slow) {
  .make-motion(@className, @keyframeName, @duration);
  .@{className}-enter,
  .@{className}-appear {
    opacity: 0;
    animation-timing-function: @ease-in-out;
  }
  .@{className}-leave {
    animation-timing-function: @ease-in-out;
  }
}

.show-error-motion(show-error, c7nShowError, 0.15s);

@keyframes c7nShowErrorIn {
  0% {
    transform: translateY(-0.05rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes c7nShowErrorOut {
  to {
    transform: translateY(-0.05rem);
    opacity: 0;
  }
}
