.@{list-prefix-cls}-bordered {
  border: @border-width-base @border-style-base @border-color-base;
  border-radius: @border-radius-base;
  .@{list-prefix-cls}-header,
  .@{list-prefix-cls}-footer,
  .@{list-prefix-cls}-item {
    padding-right: 0.24rem;
    padding-left: 0.24rem;
  }
  .@{list-prefix-cls}-item {
    border-bottom: @border-width-base @border-style-base @border-color-split;
  }

  .@{list-prefix-cls}-pagination {
    margin: 0.16rem 0.24rem;
  }

  &.@{list-prefix-cls}-sm {
    .@{list-prefix-cls}-item {
      padding-right: 0.16rem;
      padding-left: 0.16rem;
    }
    .@{list-prefix-cls}-header,
    .@{list-prefix-cls}-footer {
      padding: 0.08rem 0.16rem;
    }
  }

  &.@{list-prefix-cls}-lg {
    .@{list-prefix-cls}-header,
    .@{list-prefix-cls}-footer {
      padding: 0.16rem 0.24rem;
    }
  }
}
