@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';

@upload-prefix-cls: ~'@{c7n-pro-prefix}-upload';
@uploadlist-prefix-cls: ~'@{c7n-pro-prefix}-upload-list';

.@{upload-prefix-cls} {
  display: inline-block;

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-select {
    display: flex;
    // min-width: 1.5rem; // <-- FIXME: hard code for now
    margin-right: 0.1rem;
  }

  &-select input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &-name {
    margin-left: 0.05rem;
  }

  &:focus {
    outline: none;
  }
}

.@{uploadlist-prefix-cls} {
  margin-top: 0.05rem;
  border: @border-width-base @border-style-base @border-color-base;
  border-bottom: none;
  border-radius: @btn-border-radius-base;

  &-empty {
    display: none;
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.05rem 0.1rem;
    border-bottom: @border-width-base @border-style-base @border-color-base;

    &-name {
      padding-left: 0.2rem;
    }

    &-progress {
      display: inline-block;
      width: 100%;
      padding-left: 0.2rem;
    }

    &-icon {
      // position: absolute;
      top: 0.09rem;
    }

    &-remove {
      right: 0.1rem;
      margin-left: auto;
      cursor: pointer;
    }

    &-error {
      color: @error-color;
    }

    &-success {
      color: @success-color;
    }
  }
}
