/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.c7n-pro-upload {
  display: inline-block;
}
.c7n-pro-upload .flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.c7n-pro-upload-select {
  display: flex;
  margin-right: 0.1rem;
}
.c7n-pro-upload-select input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.c7n-pro-upload-name {
  margin-left: 0.05rem;
}
.c7n-pro-upload:focus {
  outline: none;
}
.c7n-pro-upload-list {
  margin-top: 0.05rem;
  border: 0.01rem solid #e0e0e0;
  border-bottom: none;
  border-radius: 0.02rem;
}
.c7n-pro-upload-list-empty {
  display: none;
}
.c7n-pro-upload-list-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.05rem 0.1rem;
  border-bottom: 0.01rem solid #e0e0e0;
}
.c7n-pro-upload-list-item-name {
  padding-left: 0.2rem;
}
.c7n-pro-upload-list-item-progress {
  display: inline-block;
  width: 100%;
  padding-left: 0.2rem;
}
.c7n-pro-upload-list-item-icon {
  top: 0.09rem;
}
.c7n-pro-upload-list-item-remove {
  right: 0.1rem;
  margin-left: auto;
  cursor: pointer;
}
.c7n-pro-upload-list-item-error {
  color: #d50000;
}
.c7n-pro-upload-list-item-success {
  color: #00bf96;
}
