.@{calendar-timepicker-prefix-cls} {
  position: absolute;
  top: 0.4rem;
  width: 100%;
  background-color: @component-background;

  &-panel {
    position: absolute;
    z-index: @zindex-picker;
    width: 100%;
  }

  &-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    font-size: @font-size-base;
    line-height: 1.5;
    text-align: left;
    list-style: none;
    background-color: @component-background;
    background-clip: padding-box;
    outline: none;
  }
  &-combobox {
    width: 100%;
  }

  &-column-1,
  &-column-1 &-select {
    width: 100%;
  }
  &-column-2 &-select {
    width: 50%;
  }
  &-column-3 &-select {
    width: 33.33%;
  }
  &-column-4 &-select {
    width: 25%;
  }

  &-input-wrap {
    display: none;
  }

  &-select {
    position: relative; // Fix chrome weird render bug
    float: left;
    box-sizing: border-box;
    height: 2.26rem;
    overflow: hidden;
    font-size: @font-size-base;
    border-right: @border-width-base @border-style-base @border-color-split;

    &:hover {
      overflow-y: auto;
    }

    &:first-child {
      margin-left: 0;
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }

    ul {
      box-sizing: border-box;
      width: 100%;
      max-height: 2.06rem;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      box-sizing: content-box;
      width: 100%;
      height: 0.24rem;
      margin: 0;
      line-height: 0.24rem;
      text-align: center;
      list-style: none;
      cursor: pointer;
      transition: background 0.3s ease;
      user-select: none;
    }

    li:last-child::after {
      display: block;
      height: 2.02rem;
      content: '';
    }

    li:hover {
      background: @item-hover-bg;
    }

    li&-option-selected {
      font-weight: bold;
      background: @time-picker-selected-bg;
    }

    li&-option-disabled {
      color: @btn-disable-color;
      &:hover {
        background: transparent;
        cursor: not-allowed;
      }
    }
  }
}

.@{calendar-prefix-cls}-time {
  .@{calendar-prefix-cls}-day-select {
    display: inline-block;
    padding: 0 0.02rem;
    color: @heading-color;
    font-weight: 500;
    line-height: 0.34rem;
  }

  .@{calendar-prefix-cls}-footer {
    position: relative;
    height: auto;

    &-btn {
      text-align: right;
    }

    .@{calendar-prefix-cls}-today-btn {
      float: left;
      margin: 0;
    }

    .@{calendar-prefix-cls}-time-picker-btn {
      display: inline-block;
      margin-right: 0.08rem;

      &-disabled {
        color: @disabled-color;
      }
    }
  }
}
