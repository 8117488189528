@import '../../style/themes/default';
@import '../../style/mixins/index';

@spin-prefix-cls: ~'@{c7n-prefix}-spin';
@spin-dot-default: @text-color-secondary;

.@{spin-prefix-cls} {
  .reset-component;
  position: absolute;
  display: none;
  color: @primary-color;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s @ease-in-out-circ;

  &-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
  }

  &-nested-loading {
    position: relative;
    > .@{spin-prefix-cls} {
      position: absolute;
      z-index: 4;
      width: 100%;
      height: 100%;
      max-height: 3.2rem;
      .@{spin-prefix-cls}-dot {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -@spin-dot-size / 2;
      }
      .@{spin-prefix-cls}-text {
        position: absolute;
        top: 50%;
        width: 100%;
        padding-top: (@spin-dot-size - @font-size-base) / 2 + 0.02rem;
        text-shadow: 0 0.01rem 0.02rem #fff;
      }
      &.@{spin-prefix-cls}-show-text .@{spin-prefix-cls}-dot {
        margin-top: -@spin-dot-size / 2 - 0.1rem;
      }
    }

    > .@{spin-prefix-cls}-sm {
      .@{spin-prefix-cls}-dot {
        margin: -@spin-dot-size-sm / 2;
      }
      .@{spin-prefix-cls}-text {
        padding-top: (@spin-dot-size-sm - @font-size-base) / 2 + 0.02rem;
      }
      &.@{spin-prefix-cls}-show-text .@{spin-prefix-cls}-dot {
        margin-top: -@spin-dot-size-sm / 2 - 0.1rem;
      }
    }

    > .@{spin-prefix-cls}-lg {
      .@{spin-prefix-cls}-dot {
        margin: -@spin-dot-size-lg / 2;
      }
      .@{spin-prefix-cls}-text {
        padding-top: (@spin-dot-size-lg - @font-size-base) / 2 + 0.02rem;
      }
      &.@{spin-prefix-cls}-show-text .@{spin-prefix-cls}-dot {
        margin-top: -@spin-dot-size-lg / 2 - 0.1rem;
      }
    }
  }

  &-container {
    position: relative;
    .clearfix;
  }

  &-blur {
    clear: both;
    overflow: hidden;
    opacity: 0.5;
    user-select: none;
    pointer-events: none;

    &::after {
      opacity: 0.4;
      pointer-events: auto;
    }
  }

  // tip
  // ------------------------------
  &-tip {
    color: @spin-dot-default;
  }

  // dots
  // ------------------------------

  &-dot {
    position: relative;
    display: inline-block;
    .square(@spin-dot-size);
  }

  // Sizes
  // ------------------------------
  // small
  &-sm &-dot {
    .square(@spin-dot-size-sm);
  }

  // large
  &-lg &-dot {
    .square(@spin-dot-size-lg);
  }

  &&-show-text &-text {
    display: block;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .@{spin-prefix-cls}-blur {
    background: @component-background;
    opacity: 0.5;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
