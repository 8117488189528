@import '../../style/themes/default';
@import '../../style/mixins/index';
@import '../../input/style/mixin';

@input-prefix-cls: ~'@{c7n-prefix}-input';
@select-prefix-cls: ~'@{c7n-prefix}-select';
@autocomplete-prefix-cls: ~'@{select-prefix-cls}-auto-complete';

.@{autocomplete-prefix-cls} {
  .reset-component;

  &.@{select-prefix-cls} {
    .@{select-prefix-cls} {
      &-selection {
        border: 0;
        box-shadow: none;
        &__rendered {
          height: 100%;
          margin-right: 0;
          margin-left: 0;
          padding-right: 0;
          padding-left: 0;
        }

        &--single {
          height: auto;
        }
      }
    }
    .@{select-prefix-cls}-search--inline {
      position: static;
      float: left;
    }

    &-allow-clear {
      .@{select-prefix-cls}-selection:hover .@{select-prefix-cls}-selection__rendered {
        margin-right: 0 !important;
      }
    }

    .@{input-prefix-cls} {
      background: transparent;
      &:focus,
      &:hover {
        .hover;
      }
    }

    &-lg {
      .@{select-prefix-cls}-selection__rendered {
        line-height: @input-height-lg;
      }
      .@{input-prefix-cls} {
        height: @input-height-lg;
        padding-top: @input-padding-vertical-lg;
        padding-bottom: @input-padding-vertical-lg;
      }
    }

    &-sm {
      .@{select-prefix-cls}-selection__rendered {
        line-height: @input-height-sm;
      }
      .@{input-prefix-cls} {
        height: @input-height-sm;
        padding-top: @input-padding-vertical-sm;
        padding-bottom: @input-padding-vertical-sm;
      }
    }

  }
}
