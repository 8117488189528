.@{steps-prefix-cls}-label-vertical {
  .@{steps-prefix-cls}-item {
    overflow: visible;
    &-tail {
      margin-left: 0.48rem;
      padding: 0 0.24rem;
    }
    &-content {
      display: block;
      width: @steps-desciption-max-width;
      margin-top: 0.08rem;
      text-align: center;
    }
    &-icon {
      display: inline-block;
      margin-left: 0.36rem;
    }
    &-title {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
    &-description {
      text-align: left;
    }
  }
}
