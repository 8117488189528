@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import '../../trigger-field/style/mixin';
@import '../../field/style/mixin';
@import './mixin';

@cascader-prefix-cls: ~'@{c7n-pro-prefix}-cascader';

.@{cascader-prefix-cls}-wrapper {
  .@{cascader-prefix-cls}-clear-button {
    background-color: white;
  }
}

.@{cascader-prefix-cls} {
  .trigger;

  margin: 0;

  &-trigger {
    transition: transform 0.3s;
  }

  &-expand &-trigger {
    &.icon-search {
      transform: rotate(0);
    }
    transform: rotate(180deg);
  }

  &-dropdown-menu {
    .dropdown-menu;
  }

  &-select-all-none {
    padding: 0.08rem 0.16rem;
    font-size: @font-size-sm;
    border-bottom: @border-width-base @border-style-base @border-color-base;

    span {
      color: @primary-color;
      cursor: pointer;
    }

    span + span {
      margin-left: 0.2rem;
    }
  }

  &-menus {
    position: absolute;
    z-index: @zindex-dropdown;
    font-size: @font-size-base;
    white-space: nowrap;
    background: @component-background;
    border-radius: @border-radius-base;
    box-shadow: @shadow-4;

    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &-empty,
    &-hidden {
      display: none;
    }
    &.slide-up-enter.slide-up-enter-active&-placement-bottomLeft,
    &.slide-up-appear.slide-up-appear-active&-placement-bottomLeft {
      animation-name: antSlideUpIn;
    }

    &.slide-up-enter.slide-up-enter-active&-placement-topLeft,
    &.slide-up-appear.slide-up-appear-active&-placement-topLeft {
      animation-name: antSlideDownIn;
    }

    &.slide-up-leave.slide-up-leave-active&-placement-bottomLeft {
      animation-name: antSlideUpOut;
    }

    &.slide-up-leave.slide-up-leave-active&-placement-topLeft {
      animation-name: antSlideDownOut;
    }
  }
  &-menu {
    display: inline-block;
    min-width: 1.11rem;
    height: 1.8rem;
    margin: 0;
    padding: 0;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    border-right: @border-width-base @border-style-base @border-color-split;
    &:first-child {
      border-radius: @border-radius-base 0 0 @border-radius-base;
    }
    &:last-child {
      margin-right: -0.01rem;
      border-right-color: transparent;
      border-radius: 0 @border-radius-base @border-radius-base 0;
    }
    &:only-child {
      border-radius: @border-radius-base;
    }
    &-single {
      border-right: 0;
      &:last-child {
        margin-right: 0;
      }
    }
    &-single &-item {
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-tab {
      margin-top: .05rem;
      margin-bottom: .08rem;
      padding: 0.08rem 0.19rem 0.04rem 0;
      font-size: @font-size-base;
      border-bottom: 0.015rem solid @primary-color;

      &-item {
        height: 0.2rem;
        margin-left:0.1rem;
        padding: 0.06rem 0.02rem 0.06rem @control-padding-horizontal;
        color: @text-color;
        font-weight: 500;
        font-size: 0.13rem;
        line-height: 0.2rem;
        white-space: nowrap;
        border-top: 0.01rem solid @border-color-base;
        border-right: 0.01rem solid @border-color-base;
        border-left: 0.01rem solid @border-color-base;
        border-top-left-radius: 0.02rem;
        border-top-right-radius: 0.02rem;

        cursor: pointer;

        &:hover {
          border-color: inherit;
        }

        &:last-child {
          position: relative;
          color: @primary-color;
          border-top: 0.015rem solid @primary-color;
          border-right: 0.015rem solid @primary-color;
          border-left: 0.015rem solid @primary-color;

          &::after {
            position: absolute;
            bottom: -0.03rem;
            left: 0;
            width: 100%;
            height: 0.04rem;
            color: @primary-color;
            background-color: white;
            content: ' ';
          }
        }
      }
      &-please{
        cursor: text;
        &:hover {
          background: none;
        }
      }
    }
  }
  
  &-menu-item {
    padding: 0.05rem @control-padding-horizontal;
    line-height: 0.22rem;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background: @item-hover-bg;
    }
    &-disabled {
      color: @disabled-color;
      cursor: not-allowed;
      &:hover {
        background: transparent;
      }
    }
    &-selected:not(&-disabled) {
      &,
      &:hover {
        font-weight: 600;
        background: @background-color-base;
      }
    }
    &-active:not(&-disabled) {
      &,
      &:hover {
        color: @primary-color;
        font-weight: 600;
        background: @background-color-base;
      }
    }
    &-expand {
      position: relative;
      padding-right: 0.24rem;
      &-icon {
        position: absolute;
        right: @control-padding-horizontal;
        color: @text-color-secondary;
      }
    }
    &-loading::after {
      .iconfont-font('\e64d');
      animation: loadingCircle 1s infinite linear;
    }

    & &-keyword {
      color: @highlight-color;
    }
  }
  
}
