@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import '../../field/style/mixin';

@range-prefix-cls: ~'@{c7n-pro-prefix}-range';

.@{range-prefix-cls} {
  display: none;
  &-wrapper {
    position: relative;
    display: block;
    width: 100%;
    padding: 0.04rem @input-padding-horizontal-base;
    white-space: nowrap;
    outline: none;
  }

  &-vertical {
    height: 100%;
    padding: 0 0.04rem;
  }

  &-vertical &-track {
    width: 0.04rem;
    height: 100%;

    .@{range-prefix-cls}-selection {
      bottom: 0;
      width: 0.04rem;
      height: 0;
    }
    .@{range-prefix-cls}-draghandle {
      bottom: 0;
      left: -0.05rem;
      margin: 0 0 -0.07rem 0;
    }
  }

  &-track {
    position: relative;
    height: 0.04rem;
    background: @normal-color;
    border-radius: 0.06rem; /* 将轨道设为圆角的 */
    cursor: pointer;

    .@{range-prefix-cls}-selection {
      position: absolute;
      left: 0;
      height: 0.04rem;
      background: @primary-color;
      border-radius: 0.06rem;
      cursor: inherit;
    }

    .@{range-prefix-cls}-draghandle {
      position: absolute;
      bottom: -0.05rem;
      z-index: 1;
      width: 0.13rem;
      height: 0.13rem;
      margin-left: -0.07rem;
      background: #fff;
      border: 0.015rem @border-style-base @primary-color;
      border-radius: 50%;
      cursor: inherit;
      user-select: none;
    }
  }

  &:disabled + &-track {
    cursor: not-allowed;

    .@{range-prefix-cls}-draghandle {
      border: @border-width-base @border-style-base @disabled-color;
    }

    .@{range-prefix-cls}-selection {
      background: @disabled-color;
    }
  }
  .float-label;
  &-float-label {
    padding-top: 0.11rem;
  }
  &-float-label .@{field-label-prefix-cls}-wrapper {
    left: 0;
    transform-origin: @float-label-transform-origin-high;
  }
}
