@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import 'mixin';

@pro-radio-prefix-cls: ~'@{c7n-pro-prefix}-radio';
@radio-duration: 0.3s;

.@{pro-radio-prefix-cls} {
  .radio-btn;

  &-wrapper,
  &-wrapper.@{field-prefix-cls} {
    display: inline-flex;
  }

  &-inner {
    position: relative;
    display: inline-block;
    width: @radio-size;
    height: @radio-size;
    vertical-align: middle;
    background-color: #fff;
    border: @border-width-base @border-style-base @border-color-base;
    border-radius: 50%;
    transition: all @radio-duration;

    &::before {
      position: absolute;
      top: 0.03rem;
      left: 0.03rem;
      width: 0.06rem;
      height: 0.06rem;
      background-color: @primary-color;
      border-radius: 0.03rem;
      transform: scale(0);
      opacity: 0;
      transition: all @radio-duration @ease-in-out-circ;
      content: ' ';
    }

    & + span {
      padding-right: 0.08rem;
      padding-left: 0.08rem;
    }
  }

  &:checked + &-inner {
    border-color: @primary-color;

    &::before {
      background-color: @primary-color;
      transform: scale(1);
      opacity: 1;
    }
  }

  &:hover + &-inner,
  &-wrapper:hover &-inner,
  &:focus + &-inner {
    border-color: @primary-7;

    &::before {
      background-color: @primary-7;
    }
  }

  &-highlight &-inner {
    border-color: @input-highlight-border-color;
  }

  &-highlight &:checked + &-inner {
    border-color: @input-highlight-border-color;

    &::before {
      background-color: @input-highlight-border-color;
    }
  }

  &:focus + &-inner {
    box-shadow: 0 0 @outline-blur-size @outline-width fade(@outline-color, 20%);
  }

  &:disabled + &-inner {
    background-color: @input-disabled-bg;
    border-color: @disabled-color !important;

    &::before {
      background-color: @disabled-color;
    }
  }

  &-label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-button {
    padding: 0 0.15rem;
    line-height: 0.3rem;
    background-color: #fff;

    .@{pro-radio-prefix-cls}-inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;
      height: auto;
      background-color: transparent;
      border-radius: @border-radius-base;

      &::before {
        display: none;
      }

      & + span {
        position: relative;
        z-index: 1;
        padding: 0;
      }
    }

    .@{pro-radio-prefix-cls}:checked + .@{pro-radio-prefix-cls}-inner {
      z-index: 1;

      & + span {
        color: @primary-color;
      }
    }

    &:hover .@{pro-radio-prefix-cls}-inner + span {
      color: @primary-color;
    }
  }

  &-button + &-button {
    margin-left: @btn-group-spacing;
  }
}
