@import '../../style/themes/default';
@import '../../style/mixins/index';

@image-crop-prefix-cls: ~'@{c7n-prefix}-image-crop';
@image-avatar-prefix-cls: ~'@{c7n-prefix}-avatar-crop';



.@{image-crop-prefix-cls}-modal {
  .@{c7n-prefix}-modal-body {
    padding-bottom: 16px;
  }

  .@{c7n-prefix}-modal-close-x {
    transform: translateY(25%);
  }

  .@{image-crop-prefix-cls}-container {
    position: relative;
    width: 100%;
    height: 40vh;
    margin-bottom: 16px;
  }

  .@{image-crop-prefix-cls}-control {
    display: flex;
    align-items: center;
    width: 60%;
    margin-right: auto;
    margin-left: auto;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      padding: 0;
      font-style: normal;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;

      &[disabled] {
        cursor: default;
      }
    }

    &.zoom button {
      font-size: 18px;
    }

    &.rotate button {
      font-size: 16px;

      &:first-of-type {
        transform: rotate(-20deg);
      }

      &:last-of-type {
        transform: rotate(20deg);
      }
    }

    .c7n-slider {
      flex: 1;
      margin: 0 8px;
    }
  }
}

.@{image-avatar-prefix-cls} {
  &-dragger {
    display: block;
    width: 600px;
    height: 300px;
    margin: 92px auto;
    text-align: center;

    .c7n-upload-drag {
      padding: 60px 0;
    }

    .icon {
      width: 80px;
      height: 80px;
      color: #3f51b5;
      font-size: 80px;
    }

    &-text {
      margin-top: 40px;
      font-size: 18px;
      line-height: 25px;
    }

    &-hint {
      margin-top: 16px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 13px;
      line-height: 20px;
    }
  }

  &-edit {
    position: relative;
    user-select: none;

    img {
      position: absolute;
    }

    &-wraper {
      display: flex;
      margin-top: 32px;
      margin-bottom: 16px;
      padding: 0 100px;
    }

    &-text {
      margin-top: 24px;
      font-size: 20px;
      line-height: 28px;

      >span {
        vertical-align: middle;
      }

      .icon {
        margin-left: 8px;
        font-size: 24px;
      }
    }

    &-hint {
      margin-top: 10px;
      font-size: 13px;
      line-height: 20px;
    }

    &-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 0 solid rgba(0, 0, 0, 0.36);

      div {
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px dotted rgba(0, 0, 0, 0.12);
        cursor: move;
      }

      i {
        position: absolute;
        display: block;
        width: 25px;
        height: 23px;
        border: 0 solid #000;

        &.lt {
          top: -2px;
          left: -2px;
          border-top-width: 3px;
          border-left-width: 3px;
          cursor: nw-resize;
        }

        &.rt {
          top: -2px;
          right: -2px;
          border-top-width: 3px;
          border-right-width: 3px;
          cursor: ne-resize;
        }

        &.lb {
          bottom: -2px;
          left: -2px;
          border-bottom-width: 3px;
          border-left-width: 3px;
          cursor: sw-resize;
        }

        &.rb {
          right: -2px;
          bottom: -2px;
          border-right-width: 3px;
          border-bottom-width: 3px;
          cursor: se-resize;
        }
      }
    }

    &-toolbar {
      width: 62px;
      padding: 0 16px;
      text-align: center;

      .c7n-btn {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
      }

      .icon {
        display: block;
        font-size: 30px;
        line-height: 1;
      }
    }

    &-preview {
      margin-left: 16px;
      padding-left: 32px;
      text-align: center;
      border-left: 1px solid #979797;

      &-title {
        margin-bottom: 24px;
        font-size: 18px;
      }

      &-item {
        margin-bottom: 26.5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      i {
        display: inline-block;
        background-repeat: no-repeat;
        border-radius: 50%;
      }

      p {
        margin: 0;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    &-button {
      width: 640px;
      text-align: right;
    }
  }
}