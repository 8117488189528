@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import '../../trigger-field/style/mixin';

@date-picker-prefix: ~'@{c7n-pro-prefix}-calendar';
@date-picker-width: 2.8rem;
@date-picker-horizontal-padding: 0.12rem;
@date-picker-border-base: @border-width-base @border-style-base @input-border-color;
@date-time-list-width: (@date-picker-width - @date-picker-horizontal-padding * 2 + 0.02rem) / 3;
@date-time-list-item-height: 0.24rem;

.@{date-picker-prefix} {
  &-picker {
    .trigger;

    &-popup {
      width: @date-picker-width;
      line-height: 1.5;
      background-clip: padding-box;
    }

    &-suffix {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: 100%;
      padding: 0.05rem 0.03rem;
      cursor: pointer;

      .@{iconfont-css-prefix} {
        display: block;
      }
    }
  }

  &-body {
    padding: 0.08rem @date-picker-horizontal-padding;
    overflow: hidden;

    table {
      width: 100%;
      font-size: @font-size-lg;
    }

    th {
      width: 0.33rem;
      padding: 0.06rem 0;
      font-weight: normal;
      line-height: 0.18rem;
      text-align: center;
    }
  }

  &-cell {
    height: 0.3rem;
    padding: 0.03rem 0;
    text-align: center;

    &-inner {
      display: block;
      height: 0.24rem;
      margin: 0 auto;
      padding: 0;
      line-height: 0.22rem;
      text-align: center;
      background-color: transparent;
      border: @border-width-base @border-style-base transparent;
      border-radius: @border-radius-sm;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background-color: @item-hover-bg;
      }

      &:active {
        color: #fff;
        background-color: @primary-5;
      }
    }
  }
  &-now-disabled {
    color: #bcbcbc;
    &:hover{
      color: #bcbcbc;
    }
  }

  &-old &-cell-inner,
  &-new &-cell-inner {
    color: @disabled-color;
  }

  &-today &-cell-inner {
    color: @primary-color;
    font-weight: bold;
    border-color: @primary-color;
  }

  &-selected &-cell-inner {
    color: #fff;
    background: @primary-color;
    border: @border-width-base @border-style-base transparent;

    &:hover {
      background: @primary-color;
    }
  }

  &-disabled &-cell-inner {
    width: auto;
    color: #bcbcbc;
    background: @disabled-bg;
    border: @border-width-base @border-style-base transparent;
    border-radius: 0;
    cursor: not-allowed;

    &:hover {
      background: @disabled-bg;
    }
  }

  &-disabled&-today &-cell-inner {
    position: relative;

    &::before {
      position: absolute;
      top: 0.01rem;
      left: 0.01rem;
      width: 0.2rem;
      height: 0.2rem;
      border: @border-width-base @border-style-base #bcbcbc;
      border-radius: @border-radius-base;
      content: ' ';
    }
  }

  &-day-panel &-cell-inner {
    width: 0.24rem;
  }

  &-month-panel &-cell,
  &-year-panel &-cell {
    padding: 0.2rem 0;
  }

  &-month-panel &-cell-inner,
  &-year-panel &-cell-inner {
    display: inline-table;
    padding: 0 0.06rem;
  }

  &-time-panel &-cell-inner {
    width: .4rem;
    height: @date-time-list-item-height - 0.06rem;
    line-height: @date-time-list-item-height - 0.07rem;
  }

  &-header {
    height: 0.4rem;
    padding: 0 0.07rem;
    line-height: 0.4rem;
    text-align: center;
    border-bottom: @border-width-base @border-style-base @border-color-split;
    user-select: none;

    a:hover {
      color: @link-hover-color;
    }
  }

  &-prev-year,
  &-prev-month,
  &-next-year,
  &-next-month {
    padding: 0 0.05rem;
    color: @text-color-secondary;
    font-size: 0.14rem;
    font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  }

  &-prev-year,
  &-prev-month {
    float: left;
  }

  &-next-year,
  &-next-month {
    float: right;
  }

  &-view-select {
    padding: 0 0.02rem;
    color: @text-color;
    font-weight: bold;
    line-height: 0.34rem;
  }
  
  &-footer-extra,&-footer {
    position: relative;
    height: 0.39rem;
    line-height: 0.39rem;
    text-align: center;
    border-top: @border-width-base @border-style-base @border-color-split;

    button {
      transition: all 0.3s ease-out, visibility 0s;
    }
  }

  &-footer-now-btn {
    position: absolute;
    left: 0.12rem;
  }

  &-footer-view-select {
    position: absolute;
    right: 0.12rem;
  }

  &-week-panel {
    tbody tr {
      &:hover {
        .@{date-picker-prefix}-cell-inner {
          background-color: @item-hover-bg;
        }
      }

      &:active {
        .@{date-picker-prefix}-cell-inner {
          color: #fff;
          background-color: @primary-5;
        }
      }

      .@{date-picker-prefix}-selected .@{date-picker-prefix}-cell-inner {
        color: #fff;
        background-color: @primary-color;
      }
    }

    .@{date-picker-prefix}-cell {
      &-inner {
        border-radius: 0;
      }

      &:first-child .@{date-picker-prefix}-cell-inner {
        border-top-left-radius: @border-radius-base;
        border-bottom-left-radius: @border-radius-base;
      }

      &:last-child .@{date-picker-prefix}-cell-inner {
        border-top-right-radius: @border-radius-base;
        border-bottom-right-radius: @border-radius-base;
      }
    }
  }

  &-week-cell &-cell-inner {
    color: @disabled-color;
  }

  &-time-panel {
    height: 2.26rem;

    &-inner {
      position: relative;
      height: @date-time-list-item-height;
    }
  }

  &-time-list {
    position: relative;
    float: left;
    height: 100%;

    &:first-child .@{date-picker-prefix}-time-focus {
      border-right: 0;
      border-top-left-radius: @border-radius-base;
      border-bottom-left-radius: @border-radius-base;
    }

    &:nth-child(3) .@{date-picker-prefix}-time-focus {
      border-left: 0;
      border-top-right-radius: @border-radius-base;
      border-bottom-right-radius: @border-radius-base;
    }

    ul {
      position: absolute;
      top: 50%;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      transition: top 0.3s ease;
    }

    li {
      height: @date-time-list-item-height;
      list-style: none;
    }
  }

  &-time-focus,
  &-time-focus-active {
    position: absolute;
    top: 450%;
    border: @border-width-base @border-style-base @input-border-color;
  }

  &-time-focus {
    width: 100%;
    height: 100%;

    &-active {
      height: 100%;
      .active();
      border-radius: @border-radius-base;
      transition: left 0.3s ease;
    }
  }
}
