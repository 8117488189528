@import "../../../components/style/themes/default";
@import "../../../components/style/mixins/index";

@dropdown-prefix-cls: ~"@{c7n-pro-prefix}-dropdown";

.@{dropdown-prefix-cls}-popup {
  &.slide-down-enter.slide-down-enter-active&-placement-bottomLeft,
  &.slide-down-appear.slide-down-appear-active&-placement-bottomLeft,
  &.slide-down-enter.slide-down-enter-active&-placement-bottomCenter,
  &.slide-down-appear.slide-down-appear-active&-placement-bottomCenter,
  &.slide-down-enter.slide-down-enter-active&-placement-bottomRight,
  &.slide-down-appear.slide-down-appear-active&-placement-bottomRight {
    animation-name: antSlideUpIn;
  }

  &.slide-up-enter.slide-up-enter-active&-placement-topLeft,
  &.slide-up-appear.slide-up-appear-active&-placement-topLeft,
  &.slide-up-enter.slide-up-enter-active&-placement-topCenter,
  &.slide-up-appear.slide-up-appear-active&-placement-topCenter,
  &.slide-up-enter.slide-up-enter-active&-placement-topRight,
  &.slide-up-appear.slide-up-appear-active&-placement-topRight {
    animation-name: antSlideDownIn;
  }

  &.slide-down-leave.slide-down-leave-active&-placement-bottomLeft,
  &.slide-down-leave.slide-down-leave-active&-placement-bottomCenter,
  &.slide-down-leave.slide-down-leave-active&-placement-bottomRight {
    animation-name: antSlideUpOut;
  }

  &.slide-up-leave.slide-up-leave-active&-placement-topLeft,
  &.slide-up-leave.slide-up-leave-active&-placement-topCenter,
  &.slide-up-leave.slide-up-leave-active&-placement-topRight {
    animation-name: antSlideDownOut;
  }
  .@{c7n-prefix}-menu-vertical>.@{c7n-prefix}-menu-submenu>.@{c7n-prefix}-menu-submenu-title {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}
