@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';

@intl-prefix-input: ~'@{c7n-pro-prefix}-input';
@intl-prefix-intl: ~'@{intl-prefix-input}-intl';
.@{intl-prefix-input} {
    &-suffix-button {
        .@{intl-prefix-intl} {
            &.icon-language {
                cursor: pointer;
            }
        }
    }
}


