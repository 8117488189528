@import '../../style/themes/default';
@import '../../style/mixins/index';

@radio-prefix-cls: ~'@{c7n-prefix}-radio';
@radio-group-prefix-cls: ~'@{radio-prefix-cls}-group';
@radio-inner-prefix-cls: ~'@{radio-prefix-cls}-inner';
@radio-duration: 0.3s;

.@{radio-group-prefix-cls} {
  display: inline-block;
  line-height: unset;
  &-wrapper {
    .reset-component;
    display: inline-block;
  }
  &-wrapper&-has-label {
    position: relative;
  }
  &-wrapper&-has-label & {
    padding-top: 0.17rem;
  }
  &-wrapper &-label.label-disabled {
    color: rgba(0, 0, 0, 0.54);
  }
  &-wrapper &-label {
    position: absolute;
    left: 0;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    transform: scale(0.75) translateY(0) translateZ(0.00001rem);
    transform-origin: 0 0;
  }
}

// 一般状态
.@{radio-prefix-cls}-wrapper {
  .reset-component;
  position: relative;
  display: inline-block;
  margin-right: 0.08rem;
  white-space: nowrap;
  cursor: pointer;
}

.@{radio-prefix-cls} {
  .reset-component;
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: text-bottom;
  outline: none;
  cursor: pointer;

  &-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: @border-width-base @border-style-base @radio-dot-color;
    border-radius: 50%;
    visibility: hidden;
    animation-fill-mode: both;
    content: '';
  }

  &-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: @radio-size;
    height: @radio-size;
    background-color: @radio-button-bg;
    border: @border-width-base @border-style-base @radio-border-color;
    border-radius: 50%;
    transition: all @radio-duration;
  }

  &-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
  }
}

// 选中状态
.@{radio-prefix-cls}-checked {
  .@{radio-inner-prefix-cls} {
    padding: 0.02rem;
    background-color: @radio-dot-color;
    background-clip: content-box;
    border-color: @radio-dot-color;
  }
}
.@{radio-prefix-cls}-disabled.@{radio-prefix-cls}-checked {
  .@{radio-inner-prefix-cls} {
    background-color: @radio-disabled-check-bg;
    border-color: @radio-border-color;
  }
}
.@{radio-prefix-cls}-wrapper-disabled {
  cursor: not-allowed;
}
.@{radio-prefix-cls}-disabled {
  cursor: not-allowed;

  & + span {
    color: @input-disabled-color;
    cursor: not-allowed;
  }
}

span.@{radio-prefix-cls} + * {
  padding-right: 0.08rem;
  padding-left: 0.08rem;
}

.@{radio-prefix-cls}-button-wrapper {
  position: relative;
  display: inline-block;
  height: @btn-height-base;
  margin: 0;
  padding: 0 @padding-md - 0.01rem;
  color: @radio-button-color;
  line-height: @btn-height-base - 0.02rem;
  background: @radio-button-bg;
  border: @border-width-base @border-style-base @border-color-base;
  // strange align fix for chrome but works
  // https://gw.alipayobjects.com/zos/rmsportal/VFTfKXJuogBAXcvfAUWJ.gif
  border-top-width: @border-width-base + 0.0002rem;
  border-left: 0;
  cursor: pointer;
  transition: all 0.3s ease;

  a {
    color: @radio-button-color;
  }

  > .@{radio-prefix-cls}-button {
    display: block;
    width: 0;
    height: 0;
    margin-left: 0;
  }

  .@{radio-group-prefix-cls}-large & {
    height: @input-height-lg;
    font-size: @font-size-lg;
    line-height: @input-height-lg - 0.02rem;
  }

  .@{radio-group-prefix-cls}-small & {
    height: @input-height-sm;
    padding: 0 @control-padding-horizontal-sm - 0.01rem;
    line-height: @input-height-sm - 0.02rem;
  }

  &:not(:first-child) {
    &::before {
      position: absolute;
      top: 0;
      left: -0.01rem;
      display: block;
      width: 0.01rem;
      height: 100%;
      background-color: @border-color-base;
      content: '';
    }
  }
  &:first-child {
    border-left: @border-width-base @border-style-base @border-color-base;
    border-radius: @border-radius-base 0 0 @border-radius-base;
  }

  &:last-child {
    border-radius: 0 @border-radius-base @border-radius-base 0;
  }

  &:first-child:last-child {
    border-radius: @border-radius-base;
  }

  .@{radio-prefix-cls}-inner,
  input[type='checkbox'],
  input[type='radio'] {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &-checked {
    z-index: 1;
    color: @radio-dot-color;
    background: @radio-button-bg;
    border-color: @radio-dot-color;
    box-shadow: -0.01rem 0 0 0 @radio-dot-color;
    &::before {
      background-color: @radio-dot-color !important;
      opacity: 0.1;
    }
    &:first-child {
      border-color: @radio-dot-color;
      box-shadow: none !important;
    }

    &:active {
      color: @radio-button-active-color;
      border-color: @radio-button-active-color;
      box-shadow: -0.01rem 0 0 0 @radio-button-active-color;
    }
  }

  &-disabled {
    color: @disabled-color;
    background-color: @input-disabled-bg;
    border-color: @border-color-base;
    cursor: not-allowed;

    &:first-child {
      color: @disabled-color;
      background-color: @input-disabled-bg;
      border-color: @border-color-base;
      border-left-color: @border-color-base;
    }
  }

  &-disabled&-checked {
    color: #fff;
    background-color: #e6e6e6;
    border-color: @border-color-base;
    box-shadow: none;
  }
}
