@import '../../field/style/mixin';

.radio-btn() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: inherit;
  opacity: 0;

  &-wrapper {
    position: relative;
    display: inline-block;
    align-items: center;
    line-height: @radio-line-height;
    white-space: @radio-label-white-space;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  &-disabled {
    cursor: not-allowed;
  }

  .float-label;
  &-float-label {
    padding-top: 0.07rem;
  }
}
