@import '../../style/themes/default';
@import '../../style/mixins/index';

@upload-prefix-cls: ~'@{c7n-prefix}-upload';
@upload-item: ~'@{c7n-prefix}-upload-list-item';
@upload-pictrue-card-size: 1.04rem;

.@{upload-prefix-cls} {
  .reset-component;
  outline: 0;

  p {
    margin: 0;
  }

  &-btn {
    display: block;
    width: 100%;
    outline: none;
  }

  input[type='file'] {
    cursor: pointer;
  }

  &&-select {
    display: inline-block;
  }

  &&-select-picture-card {
    display: table;
    width: @upload-pictrue-card-size;
    height: @upload-pictrue-card-size;
    //margin-right: 0.08rem;
    //margin-bottom: 0.08rem;
    margin: 0.04rem;
    text-align: center;
    vertical-align: top;
    background-color: @background-color-light;
    border: @border-width-base dashed @border-color-base;
    border-radius: @border-radius-base;
    cursor: pointer;
    transition: border-color 0.3s ease;

    > .@{upload-prefix-cls} {
      display: table-cell;
      width: 100%;
      height: 100%;
      padding: 0.08rem;
      text-align: center;
      vertical-align: middle;
    }

    &:hover {
      border-color: @primary-color;
    }
  }

  &&-drag-btn {
    display: inline-flex;

    > .@{upload-prefix-cls} {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  &&-drag {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0.16rem 0;
    text-align: center;
    background: @background-color-light;
    border: @border-width-base dashed @border-color-base;
    border-radius: @border-radius-base;
    cursor: pointer;
    transition: border-color 0.3s;

    &.@{upload-prefix-cls}-drag-hover:not(.@{upload-prefix-cls}-disabled) {
      border: 0.02rem dashed @primary-5;
    }

    &.@{upload-prefix-cls}-disabled {
      cursor: not-allowed;
    }

    .@{upload-prefix-cls}-btn {
      display: table;
      height: 100%;
    }

    .@{upload-prefix-cls}-drag-container {
      display: table-cell;
      vertical-align: middle;
    }

    &:not(.@{upload-prefix-cls}-disabled):hover {
      border-color: @primary-5;
    }

    p.@{upload-prefix-cls}-drag-icon {
      .@{iconfont-css-prefix} {
        color: @primary-5;
        font-size: 0.48rem;
      }
      margin-bottom: 0.2rem;
    }
    p.@{upload-prefix-cls}-text {
      margin: 0 0 0.04rem;
      color: @heading-color;
      font-size: @font-size-lg;
    }
    p.@{upload-prefix-cls}-hint {
      color: @text-color-secondary;
      font-size: @font-size-base;
    }
    .@{iconfont-css-prefix}-plus {
      color: @disabled-color;
      font-size: 0.3rem;
      transition: all 0.3s;
      &:hover {
        color: @text-color-secondary;
      }
    }
    &:hover .@{iconfont-css-prefix}-plus {
      color: @text-color-secondary;
    }
  }
}

.@{upload-prefix-cls}-list-drag {
  &::before {
    display: none !important;
  }
}

.@{upload-prefix-cls}-list {
  .reset-component;
  .clearfix;
  &-item {
    position: relative;
    height: 0.22rem;
    margin-top: 0.08rem;
    font-size: @font-size-base;
    &-name {
      display: inline-block;
      width: 100%;
      padding-left: @font-size-base + 0.08rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-info {
      height: 100%;
      padding: 0 0.12rem 0 0.04rem;
      transition: background-color 0.3s;

      > span {
        display: block;
      }

      .@{iconfont-css-prefix}-loading,
      .@{iconfont-css-prefix}-attach_file {
        position: absolute;
        top: @font-size-base / 2 - 0.02rem;
        color: @text-color-secondary;
        font-size: @font-size-base;
      }
    }

    .@{iconfont-css-prefix}-close {
      .iconfont-size-under-12px(0.1rem);
      position: absolute;
      top: 0;
      right: 0.04rem;
      color: @text-color-secondary;
      line-height: 0.22rem;
      cursor: pointer;
      opacity: 0;
      transition: all 0.3s;
      &:hover {
        color: @text-color;
      }
    }

    &:hover &-info {
      background-color: @item-hover-bg;
    }

    &:hover .@{iconfont-css-prefix}-close {
      opacity: 1;
    }

    &-error,
    &-error .@{iconfont-css-prefix}-attach_file,
    &-error &-name {
      color: @error-color;
    }

    &-error .@{iconfont-css-prefix}-close {
      color: @error-color !important;
      opacity: 1;
    }

    &-progress {
      position: absolute;
      bottom: -0.12rem;
      width: 100%;
      padding-left: @font-size-base + 0.12rem;
      font-size: @font-size-base;
      line-height: 0;
    }
  }

  &-picture,
  &-picture-card {
    .@{upload-item} {
      position: relative;
      height: 0.66rem;
      padding: 0.08rem;
      background-color: #fff;
      border: @border-width-base @border-style-base @border-color-base;
      border-radius: @border-radius-base;
      &:hover {
        background: transparent;
      }
      &-error {
        border-color: @error-color;
      }
    }

    .@{upload-item}-info {
      padding: 0;
    }

    .@{upload-item}:hover .@{upload-item}-info {
      background: transparent;
    }

    .@{upload-item}-uploading {
      border-style: dashed;
    }

    .@{upload-item}-thumbnail {
      position: absolute;
      top: 0.08rem;
      left: 0.08rem;
      width: 0.48rem;
      height: 0.48rem;
    }

    .@{upload-item}-thumbnail img {
      display: block;
      width: 0.48rem;
      height: 0.48rem;
      overflow: hidden;
    }

    .@{upload-item}-thumbnail.@{iconfont-css-prefix}::before {
      color: @text-color-secondary;
      font-size: 0.24rem;
      line-height: 0.48rem;
    }

    .@{upload-item}-name {
      display: inline-block;
      box-sizing: border-box;
      max-width: 100%;
      margin: 0 0 0 0.08rem;
      padding-right: 0.08rem;
      padding-left: 0.48rem;
      overflow: hidden;
      line-height: 0.44rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: all 0.3s;
    }

    .@{upload-item}-uploading .@{upload-item}-name {
      line-height: 0.28rem;
    }

    .@{upload-item}-progress {
      bottom: 0.14rem;
      width: ~'calc(100% - .24rem)';
      margin-top: 0;
      padding-left: 0.56rem;
    }

    .@{iconfont-css-prefix}-close {
      position: absolute;
      top: 0.08rem;
      right: 0.08rem;
      line-height: 1;
    }
  }

  &-picture-card {
    display: inline;

    &.@{upload-prefix-cls}-list::after {
      display: none;
    }
    .@{upload-item} {
      float: left;
      width: @upload-pictrue-card-size;
      height: @upload-pictrue-card-size;
      //margin: 0 0.08rem 0.08rem 0;
      margin: 0.04rem;
    }

    .@{upload-item}-info {
      position: relative;
      height: 100%;
      overflow: hidden;

      &::before {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: all 0.3s;
        content: ' ';
      }
    }

    .@{upload-item}:hover .@{upload-item}-info::before {
      opacity: 1;
    }

    .@{upload-item}-actions {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      white-space: nowrap;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.3s;

      .@{iconfont-css-prefix}-visibility,
      .@{iconfont-css-prefix}-delete {
        z-index: 10;
        width: 0.16rem;
        margin: 0 0.04rem;
        color: @text-color-dark;
        font-size: 0.16rem;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: #fff;
        }
      }
    }

    .@{upload-item}-info:hover + .@{upload-item}-actions,
    .@{upload-item}-actions:hover {
      opacity: 1;
    }

    .@{upload-item}-thumbnail,
    .@{upload-item}-thumbnail img {
      position: static;
      display: block;
      width: 100%;
      height: 100%;
    }

    .@{upload-item}-name {
      display: none;
      margin: 0.08rem 0 0;
      padding: 0;
      line-height: @line-height-base;
      text-align: center;
    }

    .anticon-picture + .@{upload-item}-name {
      display: block;
    }

    .@{upload-item}-uploading {
      &.@{upload-item} {
        background-color: @background-color-light;
      }

      .@{upload-item}-info {
        height: auto;
        &::before,
        .@{iconfont-css-prefix}-visibility,
        .@{iconfont-css-prefix}-delete {
          display: none;
        }
      }

      &-text {
        margin-top: 0.18rem;
        color: @text-color-secondary;
      }
    }

    .@{upload-item}-progress {
      bottom: 0.32rem;
      padding-left: 0;
    }
  }

  .@{upload-prefix-cls}-success-icon {
    color: @success-color;
    font-weight: bold;
  }

  .@{upload-prefix-cls}-animate-enter,
  .@{upload-prefix-cls}-animate-leave,
  .@{upload-prefix-cls}-animate-inline-enter,
  .@{upload-prefix-cls}-animate-inline-leave {
    animation-duration: 0.3s;
    animation-fill-mode: @ease-in-out-circ;
  }

  .@{upload-prefix-cls}-animate-enter {
    animation-name: uploadAnimateIn;
  }

  .@{upload-prefix-cls}-animate-leave {
    animation-name: uploadAnimateOut;
  }

  .@{upload-prefix-cls}-animate-inline-enter {
    animation-name: uploadAnimateInlineIn;
  }

  .@{upload-prefix-cls}-animate-inline-leave {
    animation-name: uploadAnimateInlineOut;
  }
}

@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
