@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import '../../trigger-field/style/mixin';
@import '../../field/style/mixin';
@import './mixin';

@select-prefix-cls: ~'@{c7n-pro-prefix}-select';
@checkbox-prefix-cls: ~'@{c7n-prefix}-checkbox';

.@{select-prefix-cls} {
  .trigger;

  margin: 0;

  &-disabled {
    i {
      color: @input-border-color;
    }
  }

  &-trigger {
    transition: transform 0.3s;
  }

  &-expand &-trigger {
    transform: rotate(180deg);
    &.icon-search {
      transform: none;
    }
    &-common-item-wrapper {
      display: none !important;
    }
  }

  &-dropdown-menu {
    .dropdown-menu;
  }

  .select-all;

  &-common-item {
    margin-top: 0.04rem;
    color: @label-color;
    background: @component-background;
  }

  &-common-item-selected {
    color: @primary-color;
  }

  &-common-item-label {
    display: inline-block;
    margin-top: 0.04rem;
    margin-right: 0.04rem;
  }
}
