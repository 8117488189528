@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import '../../text-field/style/mixin';
@import '../../trigger-field/style/mixin';
@import '../../select/style/mixin';
@import '../../output/style/mixin';
@import 'filter-select';
@import 'field-list';
@import 'customization';

@table-prefix-cls: ~'@{c7n-pro-prefix}-table';
@table-border: @border-width-base @border-style-base @table-border-color;

.@{table-prefix-cls} {
  position: relative;
  color: @text-color;
  font-size: @font-size-base;
  outline: none;

  table {
    font-size: inherit;
    text-align: left;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
  }

  &-header {
    margin-bottom: 0.1rem;
    padding: 0.08rem;
    font-weight: 400;
    font-size: 0.16rem;
    border-bottom: @table-border;
  }

  &-content {
    position: relative;
    overflow: hidden;

    &-overflow {
      overflow-x: auto;
    }
  }

  &-footer {
    padding: 0.08rem;
    text-align: left;
  }

  &-export-progress-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &-cell {
    margin: 0;
    padding: @table-cell-padding-vertical-base @table-cell-padding-horizontal-base;
    transition: all .3s;

    &-inner {
      position: relative;
      display: block;
      flex: 1;
      padding: @table-padding-vertical @table-padding-horizontal;
      line-height: @output-line-height;
      border-radius: @table-editable-cell-border-radius-base;

      &:focus {
        outline: none;
      }

      &:empty::before {
        display: inline-block;
        width: 0;
        content: ' ';
      }

      &-bordered {
        border: @border-width-base @border-style-base transparent;
      }

      &-edit {
        background-color: transparent;

        input {
          font-weight: 700;
          border-color: transparent;
        }
      }

      &-fixed-height {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-auto-height {
        word-break: break-all;
      }

      &-row-height-fixed {
        padding: 0 @table-padding-horizontal;
        overflow: hidden;
        line-height: inherit;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-row-height-fixed&-fixed-height {
        padding: .07rem @table-padding-horizontal;
        white-space: normal;
        overflow-wrap: break-word;
      }

      .multi;

      &-row-height-fixed&-multi {
        &-label,
        &-value {
          overflow: hidden;
          line-height: inherit;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .@{c7n-pro-prefix}-validation-message {
          padding-left: 0;
          font-size: inherit;
        }
      }

      &-dirty::after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 0;
        white-space: normal;
        border-top: 0.06rem solid @primary-color;
        border-right: 0.06rem solid transparent;
        content: ' ';
      }

      &-invalid::after {
        border-top-color: @error-color;
      }
    }

    &-prefix {
      float: left;
      padding-right: @table-padding-horizontal;
      padding-left: @table-padding-horizontal;

      > * {
        vertical-align: middle;
      }
    }

    &-fix-left,
    &-fix-right {
      position: sticky !important;
      z-index: 2;
      background-color: #fff;
      background-image: linear-gradient(to right, @component-background 0%, @component-background 100%);
      background-blend-mode: multiply;
    }

    &-multiple-block {
      .tag-block;
      vertical-align: text-top;
    }

    &-aggregation {
      .@{c7n-pro-prefix}-select-box {
        display: flex;
        line-height: inherit;

        &-wrapper {
          display: block;
        }
      }
    }

    &:not(&-aggregation) &-inner > * {
      vertical-align: middle;
    }

    &-tree {
      &-treenode {
        display: flex;
        align-items: flex-start;
        padding: 0 0 (@padding-xs / 2) 0;
        outline: none;

        .@{table-prefix-cls}-cell-inner:not(.@{table-prefix-cls}-cell-inner-editable) {
          padding-right: 0;
          padding-left: 0;
        }
      }

      &-node-content-wrapper {
        flex: 1;
        overflow: hidden;
      }

      &-switcher {
        flex: none;
        width: .18rem;
        margin: 0;
        line-height: 1;
        text-align: center;
        cursor: pointer;

        &-noop {
          width: 0;
          cursor: default;
        }

        &_close .@{iconfont-css-prefix}-arrow_drop_down {
          transform: rotate(-90deg);
        }
      }

      &-indent {
        align-self: stretch;
        line-height: 1;
        white-space: nowrap;
        user-select: none;

        &-unit {
          display: inline-block;
          width: @tree-title-height;
        }
      }

      &-title {
        display: flex;
        align-items: center;
        font-size: @font-size-sm;
        line-height: .2rem;

        .@{table-prefix-cls}-cell-inner-editable {
          line-height: .18rem;
        }
      }
    }

    &-label:not(:empty) {
      margin-right: .04rem;
      color: rgba(0, 0, 0, 0.65);
    }

    &-expand {
      display: flex;
      align-items: center;
      color: @primary-color;

      &-btn {
        padding: 0;
        background-color: @btn-default-bg;
        border: none;
        cursor: pointer;
      }

      &-menu-btn {
        display: inline-block;
        margin-left: .05rem;
        padding: .03rem .04rem;
        cursor: pointer;

        &:hover {
          background-color: @item-active-bg;
        }

        &::before {
          display: block;
          border-right: .03rem solid transparent;
          border-bottom: .03rem solid currentColor;
          border-left: .03rem solid transparent;
          content: '';
        }

        &::after {
          display: block;
          margin-top: .02rem;
          border-top: .03rem solid currentColor;
          border-right: .03rem solid transparent;
          border-left: .03rem solid transparent;
          content: '';
        }
      }
    }
  }

  &-thead &-cell,
  &-tfoot &-cell {
    position: relative;
    color: @table-header-color;
    font-weight: @table-header-font-weight;
    background-color: @table-header-bg;
  }

  .border;

  &-cell-command + &-cell-command {
    margin-left: @btn-group-spacing;
  }

  &-aggregation &-cell {
    vertical-align: top;

    &-command {
      display: block;
      height: 0.2rem;
      margin-bottom: 0.04rem;
      margin-left: 0 !important;
      text-align: inherit;
    }
  }

  &-selection-column,
  &-row-number-column,
  &-customized-column {
    &,
    .@{table-prefix-cls}-cell-inner {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  &-editor {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateX(-1000rem);

    &-lock {
      z-index: 2;
    }

    > * {
      display: block !important;
    }

    .@{table-prefix-cls}-multi-label {
      opacity: 0;
    }

    .@{table-prefix-cls}-multi-value > * {
      width: calc(100% - 0.1rem);
    }
  }

  &-body {
    position: relative;
    overflow: auto;
  }

  &-head {
    overflow: hidden;
  }

  &-foot {
    position: relative;
    overflow-x: auto;
    background-color: @component-background;
  }

  &-export-quantity {
    margin-top: .1rem;

    span {
      line-height: .3rem
    }

    .@{c7n-prefix}-col-18 {
      text-align: right;
    }
  }

  &-fixed-left,
  &-fixed-right {
    position: absolute;
    top: 0;
    z-index: 1;
    overflow: hidden;
    box-shadow: none;

    .@{table-prefix-cls}-head,
    .@{table-prefix-cls}-body {
      overflow-x: hidden;
      overflow-y: auto;
    }

    .@{table-prefix-cls}-body {
      background-color: #fff;
    }

    .@{table-prefix-cls}-foot {
      overflow-x: hidden;
    }

    table {
      background-color: #fff;
      transition: width 0.3s;
    }
  }

  &-fixed-left {
    left: 0;
  }

  &-fixed-right {
    right: 0;
  }

  &-sticky-left&-fixed-left {
    box-shadow: 0.06rem 0 0.06rem -0.04rem @shadow-color;
  }

  &-sticky-right&-fixed-right {
    box-shadow: -0.06rem 0 0.06rem -0.04rem @shadow-color;
  }

  &-sticky-shadow,
  &-cell-sticky-shadow::after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: .1rem;
    transition: all 0.3s;
    pointer-events: none;
  }

  &-sticky-shadow {
    z-index: 2;
    border-color: transparent;
  }

  &-sticky-left&-sticky-shadow {
    border-left: @table-border;
    box-shadow: inset 0.06rem 0 0.06rem -0.04rem @shadow-color;
  }

  &-sticky-right&-sticky-shadow {
    border-right: @table-border;
  }

  &-sticky-right&-sticky-shadow,
  &-cell-sticky-shadow::after {
    box-shadow: inset -0.06rem 0 0.06rem -0.04rem @shadow-color;
  }

  &-cell-sticky-shadow::after {
    left: 0;
    transform: translateX(-100%);
    content: '';
  }

  &-resizer {
    position: absolute;
    top: 0;
    width: 0.05rem;
    height: 100%;
    cursor: col-resize;
    user-select: none;

    &-left {
      left: @border-width-base / -2;
    }

    &-right {
      right: @border-width-base / -2;
    }
  }

  &-split-line {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
    display: none;
    width: 0.01rem;
    background-color: @table-split-color;
  }

  //ROW
  &-empty-row div {
    display: inline-block;
    line-height: .3rem;
    transform: translateX(-50%);
  }

  &-parity-row &-row:nth-of-type(even) > &-cell {
    background-color: @table-even-row-bg;
  }

  &-expanded-row > &-cell {
    background-color: @table-expanded-row-bg;
    border-bottom: @table-border;
  }

  &-row-mouse-batch-choose {
    background-color: @table-mouse-batch-choose-bg;
  }

  &-row:hover,
  &-row-hover,
  &-parity-row &-row:nth-of-type(even):hover,
  &-parity-row &-row-hover:nth-of-type(even) {
    &,
    & + .@{table-prefix-cls}-expanded-row {
      & > .@{table-prefix-cls}-cell {
        background-color: @table-row-hover-bg;
      }
    }
  }

  &-row-current,
  &-row-current:hover,
  &-parity-row &-row-current:nth-of-type(even) {
    &,
    & + .@{table-prefix-cls}-expanded-row {
      & > .@{table-prefix-cls}-cell {
        background-color: @table-current-row-bg;
      }
    }
  }

  &-row-selected&-row:hover,
  &-parity-row &-row-selected&-row:nth-of-type(even):hover {
    &,
    & + .@{table-prefix-cls}-expanded-row {
      & > .@{table-prefix-cls}-cell {
        background-color: @item-active-bg;
      }
    }
  }

  &-row-selected,
  &-parity-row &-row-selected:nth-of-type(even) {
    &,
    & + .@{table-prefix-cls}-expanded-row {
      & > .@{table-prefix-cls}-cell {
        background-color: @table-selected-row-bg;
      }
    }
  }

  &-cell-prefix + &-cell-inner:not(&-cell-inner-editable) {
    padding-left: 0;
  }

  &-cell-inner-editable {
    border-color: @table-editable-cell-border-color;
  }

  &-cell &-cell-inner-required {
    background-color: @table-editable-cell-required-bg;
    border-color: @table-editable-cell-required-border-color;
  }

  &-cell &-cell-inner-highlight {
    border-color: @table-editable-cell-highlight-border-color;
  }

  &-cell &-cell-inner-editable&-cell-inner-invalid {
    background-color: @table-editable-cell-error-bg;
    border-color: @table-editable-cell-error-border-color;
  }

  &-cell &-cell-inner-editable&-cell-inner-disabled {
    background-color: @table-editable-cell-disabled-bg;
    border-color: @table-editable-cell-disabled-border-color;
  }

  &-row-disabled > &-cell {
    color: @disabled-color !important;
    background-color: @disabled-bg !important;

    .@{c7n-pro-prefix}-btn {
      color: @disabled-color !important;
    }

    &:not(.@{table-prefix-cls}-selection-column) .@{table-prefix-cls}-cell-inner::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      content: ' ';
    }
  }

  &-toolbar {
    padding-bottom: 0.1rem;
    overflow: hidden;

    &-button-group {
      display: inline-block;
      vertical-align: middle;
    }

    button span {
      font-weight: 500;
    }
  }

  &-summary-group {
    display: inline-flex;
    flex-wrap: wrap;
    float: left;
    vertical-align: middle;
    background: #FFF6EA;
    border-radius: 0.02rem;
  }

  &-summary-menu-item {
    padding: 0 !important;

    button {
      height: 0.4rem;
      padding: 0 0.26rem;
    }
  }

  &-summary-button-more {
    width: 0.7rem;
    font-size: 0.12rem;
    line-height: 0.3rem;
    letter-spacing: 0;
    text-align: center;

    a {
      color: #5A6677;
    }

    i {
      vertical-align: text-bottom;
    }
  }

  &-summary-col {
    display: inline-flex;
    flex-shrink: 0;
    width: 1.7rem;
    height: 0.3rem;
    padding: 0 0.16rem;
    line-height: 0.3rem;

    div {
      font-size: 0.12rem;
      letter-spacing: 0;
    }

    &-label {
      width: 0.72rem;
      overflow: hidden;
      color: #5A6677;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
    }

    &-value {
      width: calc(100% - 0.72rem);
      overflow: hidden;
      color: #F7A835;
      white-space: nowrap;
      text-align: right;
      text-overflow: ellipsis;
    }

    &-separate {
      display: inline-block;
      width: 0.01rem;
      height: 0.3rem;
      vertical-align: top;
      background-color: #fef6e9;

      div {
        display: inline-block;
        width: 0.01rem;
        height: 0.12rem;
        margin-top: 0.1rem;
        background: rgba(87, 102, 121, 0.40);
      }
    }
  }

  &-query-bar {
    float: right;

    > * + * {
      margin-left: 0.16rem;
    }

    &-dirty-info {
      display: inline-block;
      height: @input-height-base;
      padding: 0 0.12rem;
      line-height: 2;
      vertical-align: middle;
      background-color: @background-color-light;
      border-radius: @border-radius-base;

      > * {
        vertical-align: middle;

        & + * {
          margin-left: 0.05rem;
        }
      }

      .@{iconfont-css-prefix} {
        color: @yellow-6;
      }
    }

    input {
      font-weight: 500;
    }
  }

  &-advanced-query-bar {
    padding: 0.12rem 0;

    &-button {
      display: inline-block;
      vertical-align: middle;
    }

    &-options .@{table-prefix-cls}-filter-select {
      padding-right: 0 !important;
      padding-left: 1.2rem !important;
      border-top-style: dashed !important;
      cursor: default;

      &-prefix {
        width: 1.2rem;
        line-height: 1.5;
        text-align: left;
      }
    }
  }

  &-dynamic-filter-bar {
    input::-webkit-input-placeholder { // 谷歌
      color: #6A6A6A;
    }

    input:-moz-placeholder { //火狐
      color: #6A6A6A;
    }

    input::-moz-placeholder {
      color: #6A6A6A;
    }

    input:-ms-input-placeholder { //微软
      color: #6A6A6A;
    }

    .@{c7n-pro-prefix}-input-number-placeholder, .@{c7n-pro-prefix}-select-placeholder {
      color: #6A6A6A;
    }
  }

  &-dynamic-filter-bar-suffix {
    gap: 0.08rem;
    margin-left: auto;

    .@{table-prefix-cls}-columns-chooser {
      width: unset;
      border-left: none;

      &-dropdown-menu {
        min-width: 1.8rem;
      }
    }
  }

  &-dynamic-filter-bar-prefix {
    gap: 0.08rem;

    .@{table-prefix-cls}-columns-chooser {
      width: unset;
      border-left: none;

      &-dropdown-menu {
        min-width: 1.8rem;
      }
    }
  }

  &-dynamic-filter-toolbar {
    padding-top: 0.1rem;
    text-align: right;

    .@{table-prefix-cls}-toolbar {
      text-align: right;

      .@{table-prefix-cls}-dynamic-filter-bar-suffix {
        margin-left: 0.16rem;
      }
    }
  }

  &-filter-menu {
    display: flex;
    align-items: center;

    .@{c7n-pro-prefix}-select {
      display: inline-block;
      min-width: 0.9rem;
      max-width: 2rem;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border: none;
    }

    &-expand {
      cursor: pointer;

      i {
        font-size: 0.16rem;
        background-color: #fafafa;
        border-radius: 0.02rem;
      }
    }
  }

  &-filter-content {
    position: relative;
    display: flex;
    flex-shrink: 0;
    margin-right: 0.16rem;
    padding-left: 0.08rem;
    cursor: pointer;

    &:focus-within,
    &:hover {
      background-color: rgba(0,0,0,.05);
      border-radius: 0.02rem;

      .@{table-prefix-cls}-filter-item-close {
        display: inline-block;
      }
    }
  }

  &-filter-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0.08rem 0 0;
    padding: 0.01rem 0.06rem;
    background: rgba(232, 232, 232, 0.20);
    border-radius: 0.01rem;

    span, input {
      background-color: transparent;
    }
  }

  &-add-fields {
    display: flex;
    align-items: center;
    padding: 0 0.08rem;
    color: @text-color;
    cursor: pointer;

    &:hover {
      background-color: rgba(0,0,0,.05);
      border-radius: 0.02rem;
    }
  }

  &-filter-buttons {
    display: flex;
    gap: 0.08rem;

    button:first-child {
      margin-left: 0.16rem;
    }
  }

  &-filter-status {
    width: 0.38rem;
    height: 0.16rem;
    margin: 0 0.12rem;
    padding: 0;
    color: #20D489;
    text-align: center;
    background: rgba(32, 212, 137, 0.12);
    border: none;
    border-radius: 0.02rem;

    span {
      display: block;
      font-size: 0.12rem;
      line-height: 0.16rem;
      transform: scale(0.8);
    }
  }

  &-filter-modal-item {
    font-size: 0.12rem;

    &:not(:last-child) {
      margin-bottom: 0.2rem;
    }

    &-label {
      margin-left: 0.06rem;
      vertical-align: middle;
    }
  }

  &-filter-menu-option {
    &-content {
      display: inline-block;
      width: 1.6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      i {
        display: inline-block;
      }
    }

    &-icons {
      position: absolute;
      right: 0.08rem;
      display: inline-block;
      color: @primary-color;

      i {
        display: none;
        font-size: 0.16rem;
      }
    }

    &-selected {
      display: none;
    }
  }

  &-filter-label {
    display: flex;
    align-items: center;
    margin-right: 0.02rem;
    padding-top: 0.005rem;
    color: #6A6A6A;
    font-size: 0.12rem;
  }

  &-filter-search {
    color: #A4A4A4;
    font-weight: 400;
    font-size: 0.12rem;

    i {
      height: 0.2rem;
      font-size: 0.16rem;
      line-height: 0.2rem;

      &:hover {
        color: @primary-color;
      }
    }

    &-divide {
      width: 0.01rem;
      height: 0.12rem;
      margin: 0 0.16rem;
      background-color: #DBE1F1;
    }
  }

  &-filter-item {
    display: flex;
    align-items: center;
    font-size: 0.12rem;

    &:focus-within {
      input {
        width: 1.5rem !important;
      }
    }

    label {
      input::-webkit-input-placeholder { // 谷歌
        color: rgba(0,0,0,.25);
      }

      input:-moz-placeholder { //火狐
        color: rgba(0,0,0,.25);
      }

      input::-moz-placeholder {
        color: rgba(0,0,0,.25);
      }

      input:-ms-input-placeholder { //微软
        color: rgba(0,0,0,.25);
      }

      .@{c7n-pro-prefix}-input-number-placeholder, .@{c7n-pro-prefix}-select-placeholder {
        color: rgba(0,0,0,.25);
      }
    }

    i {
      font-size: 0.15rem;
      line-height: 0.2rem;
    }

    &-close {
      position: absolute;
      top: -0.07rem;
      right: -0.07rem;
      z-index: 2;
      display: none;
      color: rgba(32,45,64,.3);
      cursor: pointer;

      &:hover {
        color: #6A6A6A;
      }
    }

    .@{c7n-pro-prefix}-input-number-plus, .@{c7n-pro-prefix}-input-number-minus {
      height: 0.14rem;
      line-height: 0.14rem;
    }

    .@{c7n-pro-prefix}-checkbox-inner {
      top: 0.08rem;
      width: 0.12rem;
      height: 0.12rem;

      &::after {
        width: 0.05rem;
        height: 0.1rem;
      }
    }

    .@{c7n-pro-prefix}-select-trigger {
      font-size: 0.15rem;
    }

    .@{c7n-pro-prefix}-select, .@{c7n-pro-prefix}-input, .@{c7n-pro-prefix}-input-number, .@{c7n-pro-prefix}-calendar-picker {
      width: auto;
      min-width: unset;
      //max-width: 2rem;
      margin: 0;
      padding: 0.02rem;
      border: none;

      &-required {
        background-color: #fff;

        & > label::before { // 谷歌
          display: inline-block;
          width: .08rem;
          margin-left: .04rem;
          color: #d50000;
          font-family: SimSun, sans-serif;
          //line-height: 1;
          vertical-align: middle;
          content: "*";
        }

        .@{c7n-pro-prefix}-input-number-placeholder, .@{c7n-pro-prefix}-select-placeholder {
          &::before {
            display: inline-block;
            width: .08rem;
            margin-left: .04rem;
            color: #d50000;
            font-family: SimSun, sans-serif;
            //line-height: 1;
            vertical-align: middle;
            content: "*";
          }
        }
      }

      &-multiple {
        & > label::before { // 谷歌
          content: "";
        }
      }

      &-invalid {
        input::-webkit-input-placeholder { // 谷歌
          color: @error-color;
        }

        input:-moz-placeholder { //火狐
          color: @error-color;
        }

        input::-moz-placeholder {
          color: @error-color;
        }

        input:-ms-input-placeholder { //微软
          color: @error-color;
        }

        .@{c7n-pro-prefix}-input-number-placeholder, .@{c7n-pro-prefix}-select-placeholder {
          color: @error-color;
        }
      }
    }

    .@{c7n-pro-prefix}-select-multiple-block > div:not(:last-child) {
      max-width: calc(100% - 0.2rem);
    }

    .@{c7n-pro-prefix}-input-number-multiple-block > div:not(:last-child) {
      max-width: calc(100% - 0.2rem);
    }
  }

  &-professional-query-bar {
    display: flex;
    padding: 0.06rem 0;

    .@{c7n-pro-prefix}-field-label, .@{c7n-pro-prefix}-field-wrapper {
      padding: 0.1rem 0.02rem;

      .@{c7n-pro-prefix}-checkbox-wrapper {
        vertical-align: sub;
      }
    }

    &-button {
      display: inline-flex;
      flex-grow: 1;
      flex-shrink: 0;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 0.1rem 0 0.1rem 0.7rem;

      span {
        vertical-align: baseline;
      }
    }
  }

  &-professional-toolbar {
    padding-top: 0.1rem;
    text-align: right;
  }

  &-pagination {
    margin: 0.1rem 0 0 0;
    text-align: right;

    &-with-selection-tips {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &-selection-tips {
    display: flex;
    flex: 1;
    align-items: center;
    text-align: left;

    .@{table-prefix-cls}-switch {
      float: none;
      margin-left: .1rem;
    }

    b {
      font-weight: 500;
    }
  }

  &-switch {
    float: left;
  }

  &-col {
    transition: width 0.3s;
  }

  &-sort-icon,
  &-help-icon {
    margin-right: 0.03rem;
    margin-left: 0.03rem;
  }

  &-sort-icon {
    color: rgba(0, 0, 0, 0.45);
    font-weight: normal;
    opacity: 0;
  }

  &-help-icon {
    color: @primary-color;
  }

  &-sort {
    &-asc &-icon,
    &-desc &-icon {
      color: inherit;
      opacity: 1;
    }

    &-desc &-icon {
      transform: rotate(180deg);
    }
  }

  &-column-resizing {
    user-select: none;
  }

  &-thead:not(&-column-resizing) &-cell-inner:hover &-sort-icon {
    opacity: 1;
  }

  &-expand-icon {
    border: @border-width-base @border-style-base transparent;
    cursor: pointer;
    transition: transform 0.3s;

    &:focus {
      color: @primary-color;
      outline: none;
      transform: scale(1.4);
    }

    &-expanded {
      transform: rotate(90deg);

      &:focus {
        transform: rotate(90deg) scale(1.4);
      }
    }

    &-spaced {
      visibility: hidden;
    }

    & + .@{c7n-pro-prefix}-checkbox {
      margin-left: @table-padding-horizontal;
    }
  }

  &-columns-chooser {
    width: 0.65rem;
    border-left: @table-border;

    &-dropdown-menu {
      min-width: 1.8rem;
    }
  }

  &-dropdown-menu {
    .dropdown-menu;
  }

  .sm;
}

.border() {
  border-top: @table-border;
  border-bottom: @table-border;

  &-cell {
    border-right: @border-width-base @border-style-base transparent;
    border-bottom: @table-border;
  }

  &-tfoot &-cell {
    border-top: @table-border;
  }

  &-tfoot &-cell,
  &-thead &-cell[colspan] {
    border-bottom-color: transparent;
  }

  &-row:last-of-type &-cell,
  &-row + &-expanded-row:last-child &-cell {
    border-bottom-style: none;
  }

  &-last-row-bordered &-row:last-of-type &-cell,
  &-last-row-bordered &-row + &-expanded-row:last-child &-cell {
    border-bottom-style: @border-style-base;
  }

  &-tfoot-bordered &-cell {
    border-bottom-color: @border-color-base;
  }

  &:not(&-bordered) &-thead&-column-resizable {
    &:hover,
    &.@{table-prefix-cls}-thead-hover {
      .@{table-prefix-cls}-cell {
        border-right-color: @table-border-color;
        border-bottom-color: @table-border-color;
      }
    }
  }

  &-bordered {
    border-right: @table-border;
    border-left: @table-border;

    .@{table-prefix-cls}-footer {
      padding-right: @table-padding-horizontal;
      padding-left: @table-padding-horizontal;
      border-bottom: @table-border;
    }

    .@{table-prefix-cls}-thead .@{table-prefix-cls}-cell-last,
    .@{table-prefix-cls}-tbody .@{table-prefix-cls}-cell:last-child,
    .@{table-prefix-cls}-tfoot .@{table-prefix-cls}-cell:last-child {
      border-right-color: transparent;
    }

    .@{table-prefix-cls}-cell,
    .@{table-prefix-cls}-body .@{table-prefix-cls}-cell:last-child,
    .@{table-prefix-cls}-fixed-left .@{table-prefix-cls}-tfoot .@{table-prefix-cls}-cell:last-child {
      border-right-color: @border-color-base;
    }

    .@{table-prefix-cls}-thead .@{table-prefix-cls}-cell[colspan] {
      border-bottom-color: @border-color-base;
    }
  }
}

.sm() {
  &-small {
    .@{table-prefix-cls}-cell {
      padding: @table-cell-padding-vertical-sm @table-cell-padding-horizontal-sm;
    }
  }
}

.@{c7n-pro-prefix}-select-dropdown-menu-item-selected {
  .@{table-prefix-cls}-filter-menu-option-selected {
    position: absolute;
    right: 0.08rem;
    display: inline-block;
    font-size: 0.16rem;
  }

  &:hover {
    .@{table-prefix-cls}-filter-menu-option-selected {
      display: none;
    }
  }
}
