@import '../../../components/style/themes/default';

@screening-prefix-cls: ~'@{c7n-pro-prefix}-screening';


.@{screening-prefix-cls} {
    &-item {
        position: relative;
        display: block;
        padding-top: .16rem;
        padding-bottom: .16rem;
        border: .01rem dotted #ddd;
        zoom: 1;

        &-title {
            display: inline-block;
            width: 10%;
            min-width: .5rem;
            vertical-align: top;
        }

        &-label {
            padding-left: .1rem;
        }

        &-operation {
            position: absolute;
            top: 0;
            right: .15rem;
            display: flex;
        }

        &-footer {
            display: block;
            clear: both;
            padding-top: .1rem;
            padding-bottom: .22rem;
            text-align: center;
        }

        &-scroll {
            max-height: .18rem;
            overflow: hidden;

            &::-webkit-scrollbar {
                width: .08rem;
                height: .08rem;
                border-radius: .1rem;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: .1rem;
                box-shadow: inset 0 0 .05rem rgba(0, 0, 0, 0.2);

                &:hover {
                    background: rgba(0, 0, 0, .2)
                }
            }
        }

        &-more &-scroll {
            max-height: .92rem;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &-multiple &-scroll {
            max-height: .92rem;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &-option {
            &-text {
                cursor: pointer;

                &:hover {
                    color: @primary-color;
                }
            }

            &-content {
                padding-bottom: .1rem;
            }

            &-selected {
                color: @primary-color;
            }
        }

        &-operation &-expanded {
            padding-top: .16rem;
            padding-bottom: .16rem;
            cursor: pointer;

            &:hover {
                color: @primary-color;
            }
        }

        &-operation &-multiple {
            padding-top: .16rem;
            padding-bottom: .16rem;
            cursor: pointer;

            &:hover {
                color: @primary-color;
            }
        }

        &-content {
            position: relative;
            display: inline-block;
            width: calc(90% - 1.3rem);
        }
    }

    &-choosed {
        position: relative;
        display: block;
        padding-top: .16rem;
        padding-bottom: .16rem;
        border: .01rem dotted #ddd;
        zoom: 1;

        &-title {
            display: inline-block;
            width: 10%;
            min-width: .5rem;
            vertical-align: top;
        }

        &-content {
            position: relative;
            display: inline-block;
            width: 90%;
        }

        &-label {
            padding-left: .1rem;
        }
    }

}