.@{steps-prefix-cls}-item-custom {
  .@{steps-prefix-cls}-item-icon {
    width: auto;
    height: auto;
    color: inherit;
    background: none;
    border: 0;
  }
  &.@{steps-prefix-cls}-item-process {
    .@{steps-prefix-cls}-item-icon {
      color: @process-icon-color;
    }
  }
}
