@import '../../style/themes/default';
@import '../../style/mixins/index';

@collapse-prefix-cls: ~'@{c7n-prefix}-collapse';

@collapse-header-bg: @background-color-light;
@collapse-active-bg: @background-color-base;

.collapse-close() {
  transform: rotate(0);
}

.collapse-open() {
  transform: rotate(90deg);
}

.@{collapse-prefix-cls} {
  .reset-component;
  background-color: @collapse-header-bg;
  border: @border-width-base @border-style-base @border-color-base;
  border-bottom: 0;
  border-radius: @collapse-panel-border-radius;

  &-trigger {
    .@{collapse-prefix-cls}-header {
      cursor: pointer;
    }
  }

  & > &-item {
    border-bottom: @border-width-base @border-style-base @border-color-base;

    &:last-child {
      &,
      & > .@{collapse-prefix-cls}-header {
        border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
      }
    }

    > .@{collapse-prefix-cls}-header {
      position: relative;
      padding: @collapse-header-padding;
      color: @heading-color;
      line-height: 0.22rem;
      transition: all 0.3s;

      .@{collapse-prefix-cls}-extra {
        float: right;
      }

      &:focus {
        outline: none;
      }

      .@{collapse-prefix-cls}-expand-icon {
        .iconfont-font('\e5c5', 0.22rem);
        height: 0.22rem;
        line-height: 1;
        vertical-align: top;
        transform: rotate(-90deg);
        cursor: pointer;

        &.@{collapse-prefix-cls}-expanded {
          vertical-align: top;
          transform: rotate(0);
        }
      }

      .@{collapse-prefix-cls}-expand-text {
        display: inline-block;
        padding-right: 0.1rem;
        cursor: pointer;
      }
    }
  }

  // Expand Icon right
  &-icon-position-right {
    & > .@{collapse-prefix-cls}-item {
      > .@{collapse-prefix-cls}-header {

        .@{collapse-prefix-cls}-expand-icon-wrapper {
          float: right;
        }

        .@{collapse-prefix-cls}-expand-icon {
          transform: rotate(90deg);
        }

        .@{collapse-prefix-cls}-expand-text {
          padding-right: 0;
          text-align: right;
          cursor: pointer;
        }
      }
    }
  }

  &-icon-position-text-right {
    & > .@{collapse-prefix-cls}-item {
      .@{collapse-prefix-cls}-expand-icon-wrapper {
        margin-left: 10px;
      }
    }
  }

  &-anim-active {
    transition: height 0.2s @ease-out;
  }

  &-content {
    padding: 0 @collapse-content-padding;
    overflow: hidden;
    color: @text-color;
    background-color: @collapse-content-bg;
    border-top: @border-width-base @border-style-base @border-color-base;

    & > &-box {
      padding-top: @collapse-content-padding;
      padding-bottom: @collapse-content-padding;
    }

    &-inactive {
      display: none;
    }
  }

  &-item:last-child {
    > .@{collapse-prefix-cls}-content {
      border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
    }
  }

  & > &-item > &-header[aria-expanded='true'] {
    .arrow {
      .collapse-open();
    }
  }

  &-borderless {
    background-color: @component-background;
    border: 0;
  }

  &-borderless > &-item {
    border-bottom: @border-width-base @border-style-base @border-color-base;
  }

  &-borderless > &-item:last-child,
  &-borderless > &-item:last-child &-header {
    border-radius: 0;
  }

  &-borderless > &-item > &-content {
    background-color: transparent;
    border-top: 0;
  }

  &-borderless > &-item > &-content > &-content-box {
    padding-top: 0.04rem;
  }

  & &-item-disabled > &-header {
    &,
    & > .arrow {
      color: @disabled-color;
      cursor: not-allowed;
    }
  }

  &-ghost {
    background-color: transparent;
    border: 0;

    > .@{collapse-prefix-cls}-item {
      border-bottom: 0;

      > .@{collapse-prefix-cls}-content {
        background-color: transparent;
        border-top: 0;

        > .@{collapse-prefix-cls}-content-box {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }
}
