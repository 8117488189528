.steps-vertical() {
  display: block;
  .@{steps-prefix-cls}-item {
    display: block;
    overflow: visible;
    &-icon {
      float: left;
      margin-right: 0.16rem;
    }
    &-content {
      display: block;
      min-height: 0.48rem;
      overflow: hidden;
    }
    &-title {
      line-height: @steps-icon-size;
    }
    &-description {
      padding-bottom: 0.12rem;
    }
  }

  > .@{steps-prefix-cls}-item > .@{steps-prefix-cls}-item-tail {
    position: absolute;
    top: 0;
    left: 0.16rem;
    width: 0.01rem;
    height: 100%;
    padding: @steps-icon-size + 0.06rem 0 0.06rem;
    &::after {
      width: 0.01rem;
      height: 100%;
    }
  }

  > .@{steps-prefix-cls}-item:not(:last-child) > .@{steps-prefix-cls}-item-tail {
    display: block;
  }

  > .@{steps-prefix-cls}-item
    > .@{steps-prefix-cls}-item-content
    > .@{steps-prefix-cls}-item-title {
    &::after {
      display: none;
    }
  }

  &.@{steps-prefix-cls}-small {
    .@{steps-prefix-cls}-item-tail {
      position: absolute;
      top: 0;
      left: 0.12rem;
      padding: @steps-small-icon-size + 0.06rem 0 0.06rem;
    }
    .@{steps-prefix-cls}-item-title {
      line-height: @steps-small-icon-size;
    }
  }
}

.@{steps-prefix-cls}-vertical {
  .steps-vertical;
}

@media (max-width: @screen-xs) {
  .@{steps-prefix-cls}-horizontal.@{steps-prefix-cls}-label-horizontal {
    .steps-vertical;
  }
}
