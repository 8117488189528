@import '../../style/themes/default';
@import '../../style/mixins/index';
@import './mixin';

// Input styles
.@{c7n-prefix}-input {
  .reset-component;
  .input;
}

.@{c7n-prefix}-input-textarea {
  .@{c7n-prefix}-input-rendered {
    position: relative;
    display: block;
    padding-top: 0.15rem;
  }
  .@{c7n-prefix}-input-label {
    bottom: auto;
  }
  .@{c7n-prefix}-input-textarea-element {
    padding: 0.1rem;
  }
}
//== Style for input-group: input with label, with bulabeltton or dropdown...
.@{c7n-prefix}-input-group {
  .reset-component;
  .input-group(~'@{c7n-prefix}-input');
  &-wrapper {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }
}

// Input with affix: prefix or suffix
.@{c7n-prefix}-input-affix-wrapper {
  .reset-component;
  .input-affix-wrapper(~'@{c7n-prefix}-input');

  .@{c7n-prefix}-input {
    min-height: 100%; // use min-height, assume that no smaller height to override
  }
}

@import './search-input';
