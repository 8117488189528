&-table {
  &-column-group {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    &-header {
      border-bottom: 1px solid #eee;
      position: absolute;
      width: 100%;
      &-content {
        display: table-cell;
        padding: 8px;
      }
    }
    &-cell {
      position: absolute;
      border-right: 1px solid #eee;
    }
  }
}
