@import '../../style/themes/default';
@import '../../style/mixins/index';
@import '../../input/style/mixin';

@mention-prefix-cls: ~'@{c7n-prefix}-mention';

.@{mention-prefix-cls} {
  &-wrapper {
    .reset-component;
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
  }

  &-editor {
    .input;
    display: block;
    height: auto; // To override height in .input mixin
    min-height: @input-height-base;
    padding: 0;
    line-height: @line-height-base;
    &-wrapper {
      height: auto;
      overflow-y: auto;
    }
  }
  &-active:not(.disabled) .@{mention-prefix-cls}-editor {
    .active;
  }
  &-wrapper.disabled .@{mention-prefix-cls}-editor {
    .disabled();
  }

  &-dropdown {
    .reset-component;
    position: absolute;
    top: -99.99rem;
    left: -99.99rem;
    z-index: @zindex-dropdown;
    min-width: 1.2rem;
    max-height: 2.5rem;
    margin-top: 1.5em;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: @component-background;
    border-radius: @border-radius-base;
    outline: none;
    box-shadow: @box-shadow-base;

    &-placement-top {
      margin-top: -0.1em;
    }

    &-notfound&-item {
      color: @disabled-color;

      .@{iconfont-css-prefix}-loading {
        display: block;
        color: @primary-color;
        text-align: center;
      }
    }
    &-item {
      position: relative;
      display: block;
      padding: 0.05rem @control-padding-horizontal;
      overflow: hidden;
      color: @text-color;
      font-weight: normal;
      line-height: 0.22rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background-color: @item-hover-bg;
      }

      &.focus,
      &-active {
        background-color: @item-active-bg;
      }

      &-disabled {
        color: @disabled-color;
        cursor: not-allowed;

        &:hover {
          color: @disabled-color;
          background-color: @component-background;
          cursor: not-allowed;
        }
      }

      &-selected {
        &,
        &:hover {
          color: @text-color;
          font-weight: bold;
          background-color: @background-color-base;
        }
      }

      &-divider {
        height: 0.01rem;
        margin: 0.01rem 0;
        overflow: hidden;
        line-height: 0;
        background-color: @border-color-split;
      }
    }
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    pointer-events: none;
    .public-DraftEditorPlaceholder-inner {
      height: auto;
      padding: 0.05rem @control-padding-horizontal - 0.01rem;
      color: @input-placeholder-color;
      white-space: pre-wrap;
      word-wrap: break-word;
      outline: none;
      opacity: 1;
    }
  }
  .DraftEditor-editorContainer .public-DraftEditor-content {
    height: auto;
    padding: 0.05rem @control-padding-horizontal - 0.01rem;
  }
}
