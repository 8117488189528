@import '../../../components/style/themes/default';

.uncheck(@mixin-checkbox-size, @check-width, @check-height) {
  position: absolute;
  top: (@mixin-checkbox-size - @check-height) / 2 - 3 * (@mixin-checkbox-size / 14);
  display: table;
  width: @check-width;
  height: @check-height;
  border: 0.02rem solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0);
  transition: all 0.1s @ease-in-back;
  content: ' ';
}

.uncheck-left(@mixin-checkbox-size: @checkbox-size) {
  &::after {
    @check-width: (@mixin-checkbox-size / 14) * 5;
    @check-height: (@mixin-checkbox-size / 14) * 10;
    .uncheck(@mixin-checkbox-size, @check-width, @check-height);
    left: (@mixin-checkbox-size - @check-width) / 2 - 1.5 * (@mixin-checkbox-size / 14);
  }
}

.uncheck-right(@mixin-checkbox-size: @checkbox-size) {
  &::after {
    @check-width: (@mixin-checkbox-size / 14) * 5;
    @check-height: (@mixin-checkbox-size / 14) * 10;
    .uncheck(@mixin-checkbox-size, @check-width, @check-height);
    right: (@mixin-checkbox-size - @check-width) / 2 - 1.5 * (@mixin-checkbox-size / 14);
  }
}

.check() {
  &::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
  }
}

.indeterminate() {
  &::after {
    @indeterminate-width: (@checkbox-size / 14) * 8;
    @indeterminate-height: (@checkbox-size / 14) * 1;
    position: absolute;
    top: (@checkbox-size - 0.05rem - @indeterminate-height) / 2;
    left: (@checkbox-size - @indeterminate-width - @checkbox-border-width * 2) / 2;
    width: @indeterminate-width;
    height: @indeterminate-height;
    transform: scale(1) !important;
    content: ' ';
  }
}
