@import '../../../components/style/themes/default';

@modal-prefix-cls: ~'@{c7n-pro-prefix}-modal';
@table-prefix-cls: ~'@{c7n-pro-prefix}-table';
@select-prefix-cls: ~'@{c7n-pro-prefix}-select';
@progress-prefix-cls: ~'@{c7n-prefix}-progress';


.@{modal-prefix-cls} {
  &-body {
    .@{table-prefix-cls} {
      &-professional-query-bar {
        &-button {
          padding: 0.1rem 0 0.1rem 0.4rem;

          span {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.@{select-prefix-cls}-lov-spin {
  .@{progress-prefix-cls}-inner {
    width: 0.2rem;
    height: 0.2rem;
    margin-right: 0.03rem;
  }
}
