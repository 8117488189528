@import '../../../components/style/themes/default';
@import '../../../components/style/mixins/index';
@import '../../radio/style/mixin';

@switch-prefix-cls: ~'@{c7n-pro-prefix}-switch';
@switch-duration: 0.36s;

.@{switch-prefix-cls} {
  &-wrapper {
    padding: @switch-wrapper-padding;

    &.@{c7n-pro-prefix}-field {
      width: auto;
    }
  }

  .radio-btn;

  &-label {
    position: relative;
    display: block;
    min-width: @switch-min-width;
    height: @switch-height;
    padding-right: 0.05rem;
    padding-left: @switch-button-size * 1.2;
    color: @switch-label-color;
    font-size: @font-size-sm;
    line-height: @switch-line-height;
    background-color: @switch-bg;
    border-radius: @switch-height / 2;

    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: @switch-button-size;
      height: @switch-button-size;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: @switch-padding-horizontal;
      background-color: @switch-button-bg;
      border-radius: 50%;
      box-shadow: @switch-button-box-shadow;
      cursor: inherit;
      transition: all @switch-duration @ease-in-out-circ;
      content: ' ';
    }
  }

  &:active + &-label::after {
    width: @switch-button-active-width;
  }

  &-focused &-label {
    box-shadow: @switch-box-shadow;
  }

  &-focused:hover &-label {
    box-shadow: none;
  }

  &-float-label {
    padding-right: .08rem;
    padding-left: .08rem;
    vertical-align: middle;
  }

  &:checked + &-label {
    padding-right: @switch-button-size * 1.2;
    padding-left: 0.05rem;
    background-color: @switch-checked-bg;

    &::after {
      left: 100%;
      margin-left: -@switch-padding-horizontal;
      background-color: @switch-checked-button-bg;
      transform: translateX(-100%);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled + &-label {
    opacity: @switch-disabled-opacity;
  }

  &-sm {
    padding: @switch-sm-wrapper-padding;
  }

  &-sm &-label {
    min-width: @switch-sm-min-width;
    height: @switch-sm-height;
    border-radius: @switch-sm-button-size / 2;

    &::after {
      width: @switch-sm-button-size;
      height: @switch-sm-button-size;
    }
  }

  &-sm &:active + &-label::after {
    width: @switch-sm-button-active-width;
  }

  &-lg {
    padding: @switch-lg-wrapper-padding;
  }

  &-lg &-label {
    min-width: @switch-lg-min-width;
    height: @switch-lg-height;
    border-radius: @switch-lg-button-size / 2;

    &::after {
      width: @switch-lg-button-size;
      height: @switch-lg-button-size;
    }
  }

  &-lg &:active + &-label::after {
    width: @switch-lg-button-active-width;
  }

  &-float-label .@{field-label-prefix-cls}-wrapper {
    left: 0;
    transform-origin: @float-label-transform-origin-high;
  }
}
